import React, { Component } from 'react';
import * as filestack from 'filestack-js';
import ReactPlayer from 'react-player';
import { Document, Outline, Page, pdfjs } from 'react-pdf';

import { withStyles } from '@material-ui/core/styles';
import { styles } from '../styles';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';

import NavigateBefore from '@material-ui/icons/NavigateBefore';
import NavigateNext from '@material-ui/icons/NavigateNext';
import ZoomIn from '@material-ui/icons/ZoomIn';
import ZoomOut from '@material-ui/icons/ZoomOut';

import { checkIfVideoLink, environment, getCorePlayerUrl } from '../helpers/helperFunctions';
import LoadingDivContainer from '../subcomponents/loadingDiv';
import InteractiveVideo from '../content/interactiveVideo';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

class FilestackPreviewGenerate extends Component {
  componentDidMount() {
    const apiKey = process.env.REACT_APP_FILESTACK_API_KEY;
    const client = filestack.init(apiKey);
    const { view } = this.props;
    const item = this.props.item;
    const { filestack_handle, file_type, file_size, downloadable, converted_for_space, alt_path, path } = item;
    let showFilestack = false,
        handlePath = '';

    if (alt_path) {
      handlePath = alt_path;
    } else {
      handlePath = path;
    }

    switch (file_type) {
      // case 'pdf':
      case 'ai':
      case 'psd':
        showFilestack = true;
        break;
      case 'doc':
      case 'docx':
      case 'xls':
      case 'xlsx':
      case 'xlsm':
      case 'ppt':
      case 'pptx':
      case 'zip':
        if (file_size > 10000000) {
          showFilestack = true;
        }
        break;
      default:
        break;
    }

    let css = '';

    if (view === 'media' && !downloadable) {
      //uploaded via filestack custom css file
      //https://github.com/filestack/filestack-viewer-sass
      // css = 'https://cdn.filestackcontent.com/Ps3tuMMgQ06ve9f3dpwJ';
      css = 'https://teams.verb.tech/viewer_no_download.css';
    }
    //add in new uploaded css file to change to dark mode, other css changes of previewer 
    else {
      // css = 'https://cdn.filestackcontent.com/6SOdWrSTiERmmIbR1xmA';
      css = 'https://teams.verb.tech/viewer.css';
    }

    if (filestack_handle && showFilestack) {
      client.preview(filestack_handle, { id: this.el.id, css: css});
    } else if (showFilestack && handlePath.includes('solo-uploads')) {
      client.preview('cache=false/src://solo-uploads/' + handlePath.replace("https://s3.amazonaws.com/solo-uploads/", ""), { id: this.el.id, css: css});
    } else if (showFilestack && handlePath.includes('solofire-usercontent')) {
      client.preview('cache=false/src://solofire-usercontent/' + handlePath.replace("https://solofire-usercontent.s3.amazonaws.com", ""), { id: this.el.id, css: css});
    } else if (showFilestack) {
      client.preview('src://my-s3/' + handlePath, { id: 'media'});
    }
  }

  render() {
    const { className } = this.props;

    return (
      <div id="previewId" className={className} ref={el => this.el = el} />
    );
  }
}

class FilePreview extends Component {
  state = {
    numPages: null,
    pageNumber: 1,
    scale: 1,
    pageLoading: false
  }

  filestackPreview = () => {
    const { classes, item, view } = this.props;

    return (
      <FilestackPreviewGenerate
        className={classes.filestackPreview}
        item={item}
        key={item._id}
        view={view}
      />
    )
  }

  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({
      numPages,
      pageNumber: 1,
      pageLoading: true
    })
  }

  onItemClick = ({ pageNumber: itemPageNumber }) => {
    this.setState({
      pageNumber: itemPageNumber
    })
  }

  changePage = (offset) => {
    const { pageNumber } = this.state

    this.setState({
      pageNumber: pageNumber + offset,
      pageLoading: true
    })
  }

  previousPage = () => {
    this.changePage(-1);
  }

  nextPage = () => {
    this.changePage(1);
  }

  changeScale = (offset) => {
    const { scale } = this.state

    let newScale = scale + offset

    if (newScale < .25) {
      newScale = .25
    } else if (newScale > 5) {
      newScale = 5
    }

    this.setState({
      scale: newScale,
      pageLoading: true
    })
  }

  previousScale = () => {
    this.changeScale(-.25);
  }

  nextScale = () => {
    this.changeScale(.25);
  }

  handleRenderSuccess = () => {
    this.setState({
      pageLoading: false
    })
  }

  fileTypeSwitch = () => {
    const { backgroundColor, color, classes, roomItemClick, selectedLinkUdid, item, view, closedLibraryItemModal } = this.props;
    let { _id, file_type, file_size, filename, name, description, thumbnail, converted_for_space, alt_path, path } = this.props.item;
    const { pageNumber, pageLoading, numPages, scale } = this.state;
    const windowHeight = document.documentElement.clientHeight - 240;

    let handlePath = '',
        verticallyCenter = classes.verticallyCenter

    if (alt_path) {
      handlePath = alt_path;
    } else {
      handlePath = path;
    }

    let url = filename,
        stagingPPTS3 = ''

    if (environment() === 'staging') {
      stagingPPTS3 = 'staging-'
    }

    if (typeof filename === 'object') {
      url = filename.filename;
    }

    if (file_type === 'link' && checkIfVideoLink(url)) {
      file_type = 'video';
      handlePath = url;
    }

    file_type = file_type ? file_type.toLowerCase() : file_type

    if (view === 'single') {
      verticallyCenter = null
    }

    switch (file_type) {
      case 'pdf':
        return (
          <div style={{ height: '100%'}}>
            <div className={classes.pdfControlsContainer}>
              <p className={classes.pdfPageNumbers}>
                Page <span className={classes.pageNumber}>{pageNumber || (numPages ? 1 : '--')}</span> of <span className={classes.pageNumber}>{numPages || '--'}</span>
              </p>
              <Button
                style={{...backgroundColor}}
                className={classes.pdfButton}
                variant="contained" 
                color="secondary"
                disabled={pageNumber <= 1}
                onClick={this.previousPage}
              >
                <NavigateBefore />
              </Button>
              <Button
                style={{...backgroundColor}} 
                className={classes.pdfButton}
                variant="contained" 
                color="secondary"
                disabled={pageNumber >= numPages}
                onClick={this.nextPage}
              >
                <NavigateNext />
              </Button>
              <span className={classes.pdfZoomContainer}>
                <IconButton className={classes.pdfZoom} onClick={this.previousScale}>
                  <ZoomOut className={`${classes.pdfZoomIcon}`} style={{...color}} />
                </IconButton>
                <IconButton className={classes.pdfZoom} onClick={this.nextScale}>
                  <ZoomIn className={`${classes.pdfZoomIcon}`} style={{...color}} />
                </IconButton>
                <span className={classes.pdfScale}>{`${scale * 100}%`}</span>
              </span>
            </div>
            <Document
              className={classes.pdfDocument}
              file={handlePath}
              onLoadSuccess={this.onDocumentLoadSuccess}
              loading={<LoadingDivContainer />}
            >
              {/*<Outline onItemClick={this.onItemClick} />*/}
              <Page 
                className={classes.centerPdf}
                height={windowHeight}
                scale={scale}
                pageNumber={pageNumber}
                onRenderSuccess={this.handleRenderSuccess}
                renderAnnotationLayer={true}
                // renderTextLayer={false}
                // renderAnnotationLayer={false}
                // renderMode="svg"
              />
            </Document>
            {pageLoading &&
              <LoadingDivContainer type="pageLoading" />
            }
          </div>
        )

      case 'ai':
      case 'psd':
        return(this.filestackPreview())

      case 'doc':
      case 'docx':
      case 'xls':
      case 'xlsx':
      case 'xlsm':
      case 'ppt':
      case 'pptx':
      case 'zip':
        if (file_type === 'zip' && converted_for_space) {
          
          //https://d1ishgacx06v9g.cloudfront.net/${stagingPPTS3}${_id}
          //https://solofire-converted-ppts.s3.amazonaws.com/

          return (
              <React.Fragment>
                <Typography variant ='h4' className={classes.convertedPPTPreviewText}>Click on presentation, then use your mouse click, arrow keys (left or right), or swipe (left or right) on a touch screen to move between the slides.</Typography>
                <iframe className={classes.convertedPPTPreview} src={`https://d1ishgacx06v9g.cloudfront.net/${stagingPPTS3}${_id}/course/index.html`}></iframe>
              </React.Fragment>
            )
        } else if (file_size < 10000000) {
          return <iframe title={'Office Document Viewer'} className={classes.filestackPreview} src={`https://view.officeapps.live.com/op/embed.aspx?src=${handlePath}`}></iframe>
        } else {
          return(this.filestackPreview())
        }

      case 'png':
      case 'jpg':
      case 'jpeg':
      case 'tiff':
      case 'gif':
        return(  
          <div className={classes.imagePreview}>
            <img className={`${classes.filestackImagePreview} ${verticallyCenter}`} src={handlePath} alt={''} />
          </div>
        )

      //all movie types?
      case 'mov':
      case 'mp4':
      case 'mpeg':
      case 'm4v':
      case 'avi':
      case 'wmv':
      case 'flv':
      case 'video':
      case '3gp':
        return (
          <div className={verticallyCenter}>
            <div className={classes.aspectRatioBox}>
              <div className={classes.aspectRatioBoxInside}>
                <div className={classes.flexboxCentering}>
                  <ReactPlayer 
                    width='100%'
                    height='100%' 
                    style={{maxHeight: 'calc(100vh - 175px)'}} 
                    url={handlePath} 
                    controls={true} 
                    playing={true}
                    allowfullscreen="true"
                    webkitallowfullscreen="true"
                    mozallowfullscreen="true"
                  />
                </div>
              </div>
            </div>
          </div>
        )

      case 'link':
      case 'showcase':
      case '360':
        const handleUrl = !url.includes('http') ? `http://${url}` : url

        return (
          <Typography variant="h4" className={classes.previewLinkContainer} onClick={() => {

            if (roomItemClick) {
              roomItemClick(item, selectedLinkUdid, true)
            }

            return
          }}> 
            <a href={handleUrl} target="_blank" className={classes.previewLink} style={{...color}} >
              Open Link
              <br />
              <Button className={classes.linkButton} style={{...backgroundColor}} variant="contained" color="secondary">{name}</Button>
            </a>
          </Typography>
        )

      case 'iv':
        if (view === 'admin') {
          return(
            <InteractiveVideo 
              libraryId={_id}
              videoId={handlePath}
              title={name}
              description={description}
              closedLibraryItemModal={closedLibraryItemModal}
            />
          )
        } else {
          return(
            <iframe src={`${getCorePlayerUrl()}?client_id=abc&user_id=111&video_id=${handlePath}`} width="100%" height="100%" className={classes.videoIframe} title="Interactive Video Player"></iframe>
          )
        }
      
      default:
        return(this.filestackPreview())
    }
  }

  render() {
    return (
      this.fileTypeSwitch()
    );
  }
}

export default withStyles(styles)(FilePreview);