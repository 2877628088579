import { verbPrimary, verbSecondary, black, notBlack, darkBlue, storm, energy, darkEnergy, navy, blue, teal, torquoise, darkTorquoise, peacock, darkPeacock, lightDolphin, dolphin, darkDolphin, darkGrayBorder, darkGray, gray, inputBorderGray, beforeLightGray, lightGray, lighterGray, evenLighterGray, borderGray, azureMist, white, lightOpaqueBackground, opaqueBackground, menuDivider, companyText, helperText, highlight, listHover, verbPurple, verbLightPurple, verbDarkPurple, verbSeaGreen,verbGray, verbDarkHeading, verbDarkerBlue} from '../theme';

const iphone = /iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
const ipad = /iPad/.test(navigator.userAgent) && !window.MSStream;
let heightOffset = '0px';

if (iphone) {
  heightOffset = '110px';
} else if (ipad) {
  heightOffset = '40px';
}

export const styles = theme => ({
  header: {
    width: '100%',
    position: 'fixed',
    zIndex: 1000
  },
  root: {
    height: '100%',
    maxWidth: 1650,
    flexGrow: 1,
    margin: '0 auto 10px',
    padding: '108px 34px 0',
    overflow: 'hidden',
    [theme.breakpoints.down('sm')]: {
      padding: '108px 20px 0'
    },
    [theme.breakpoints.down('xs')]: {
      padding: '108px 10px 0 20px'
    }
  },
  contactsRoot: {
    padding: '87px 34px 0'
  },
  onboardingRoot: {
    padding: '108px 0 0',
    height: 'initial'
  },
  rootExtendedHeader: {
    paddingTop: '150px'
  },
  rootExtendedHeaderAnalytics: {
    [theme.breakpoints.down('md')]: {
      paddingTop: '118px'
    },
    [theme.breakpoints.down('sm')]: {
      height: 'initial',
      overflowY: 'scroll'
    }
  },
  rootTeamsHeader: {
    paddingTop: 112
  },
  rootTeamsHeaderLiveOnly: {
    paddingTop: 90
  },
  rootBillingLiveOnly: {
    paddingTop: 70,
    paddingBottom: 60,
    overflowY: 'auto'
  },
  overlay: {
    zIndex: 10000,
    position: 'fixed',
    top: 0,
    left: 0,
    height: '100vh',
    width: '100vw',
    backgroundColor: '#00000080'
  },
  roomWideBackground: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    flex: '1 1 auto',
    // backgroundColor: notBlack,
    padding: '0 30px',
    [theme.breakpoints.down(1415)]: {
      padding: '0px'
    }
  },
  showcaseWideBackground: {
    position: 'relative',
    padding: 0,
    [theme.breakpoints.down(1415)]: {
      padding: '0px'
    }
  },
  roomWideBackgroundLightBorder: {
    borderBottom: `1px solid ${borderGray}`
  },
  roomWideBackgroundWhite: {
    flex: '1 1 auto',
    position: 'relative',
    backgroundColor: white,
    padding: '0 30px 30px',
    [theme.breakpoints.down(1415)]: {
      padding: '0px'
    }
  },
  flex: {
    flex: 1,
  },
  bold: {
    fontWeight: 300
  },
  bolder: {
    fontWeight: 400
  },
  strikethrough: {
    textDecoration: 'line-through'
  },
  formNoMaxHeight: {
    maxHeight: 'initial',
    textAlign: 'center'
  },
  billingForm: {
    maxHeight: 'initial'
  },
  modalContainer: {
    outline: 'none !important'
  },
  modalTitle: {
    width: '94%',
    maxWidth: '94%',
    wordBreak: 'break-all'
  },
  modalTitleItem: {
    fontStyle: 'italic'
  },
  inline: {
    display: 'inline'
  },
  loadingContainer: {
    width: '100%',
    margin: '0 auto',
    paddingTop: '15%',
    textAlign: 'center'
  },
  smallLoadingContainer: {
    paddingTop: '4%'
  },
  interactiveAnalyticsLoadingContainer: {
    paddingTop: 0
  },
  noPaddingLoadingContainer: {
    paddingTop: 0
  },
  loadingH2: {
    fontSize: '2.2rem'
  },
  loading: {
    width: '90%',
    paddingTop: '4%',
    maxWidth: 300
  },
  loadingSmall: {
    width: '90%',
    paddingTop: 0,
    maxWidth: 170
  },
  loadingReport: {
    width: '90%',
    maxWidth: 300
  },
  divContainer: {
    height: '100%'
  },
  
  //HEADER
  appBar: {
    boxShadow: 'none'
  },
  headerContainer: {
    width: '100%'
  },
  headerContainerExtended: {
    paddingTop: 0
  },
  mediaViewerHeaderContainer: {
    paddingTop: 0
  },
  topBar: {
    // height: '150px',
    // height: 90,
    // backgroundImage: 'url(/images/solofire-background.png)',
    // backgroundRepeat: 'no-repeat',
    // backgroundSize: 'cover',
    backgroundColor: black,
    padding: 0,
    // borderBottom: `5px solid ${darkTorquoise} !important`,
    [theme.breakpoints.down('lg')]: {
      display: 'block'
    }
  },
  topBarExtended: {
    // height: 135
  },
  topBarMediaViewer: {
    height: 75
  },
  mainNav: {
    width: '100%',
    maxWidth: 1650,
    height: 86,
    padding: '14px 30px 0',
    margin: '0 auto',
    [theme.breakpoints.down('md')]: {
      width: 'calc(100% - 65px)',
      paddingLeft: 0,
      paddingRight: 0
    }
  },
  mainNavLiveOnly: {
    height: 70,
    padding: '7px 20px',
    width: '100%'
    // [theme.breakpoints.down('sm')]: {
    //   width: 'calc(100% - 40px)'
    // },
    // [theme.breakpoints.down('xs')]: {
    //   width: 'calc(100% - 20px)'
    // }
  },
  menuContainerReducedFour: {
    [theme.breakpoints.between('md', 'md')]: {
      maxWidth: '60%',
      flexBasis: '60%',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  appUserGearMenu: {
    display: 'block !important'
  },
  buttonAreaContainerLiveOnly: {
    [theme.breakpoints.down('md')]: {
      position: 'relative',
      top: -1
    }
  },
  headerButtonAreaLiveOnly: {
    [theme.breakpoints.down('md')]: {
      maxWidth: '70%',
      flexBasis: '70%'
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: '30%',
      flexBasis: '30%'
    }
  },
  menuContainerReducedEight: {
    [theme.breakpoints.between('md', 'md')]: {
      maxWidth: '40%',
      flexBasis: '40%',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  headerNameAreaLiveOnly: {
    [theme.breakpoints.down('md')]: {
      maxWidth: '30%',
      flexBasis: '30%'
    },
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      maxWidth: '70%',
      flexBasis: '70%'
    }
  },
  drawerIcon: {
    color: white,
    fontSize: 38,
    [theme.breakpoints.down('md')]: {
      fontSize: 30
    }
  },
  mobileMenuButton: {
    display: 'none',
    float: 'left',
    paddingRight: 0,
    paddingTop: 23,
    [theme.breakpoints.down('md')]: {
      display: 'inline',
      minWidth: 40,
      paddingTop: 27
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: 32
    }
  },
  mainNavLogin: {
    padding: '0px 20px'
  },
  mainNavExtended: {
    height: 80,
    paddingTop: 10,
    [theme.breakpoints.down('sm')]: {
      height: 60,
      paddingTop: 0
    }
  },
  //mobilmenubutton
  //
  subNavContainer: {
    backgroundColor: beforeLightGray
  },
  subNav: {
    width: '100%',
    maxWidth: 1650,
    margin: '0 auto',
    padding: '10px 30px 10px',
    [theme.breakpoints.down('sm')]: {
      overflow: 'hidden'
    }
  },
  subNavMarginLeft: {
    marginLeft: 65,
    [theme.breakpoints.down('lg')]: {
      marginLeft: 10
    },
    [theme.breakpoints.down('sm')]: {
      overflow: 'hidden'
    }
  },
  miniPlusButton: {
    minHeight: 25,
    height: 25,
    width: 25,
    '&>span>svg': {
      fontSize: 19
    }
  },
  plusButton: {
    zIndex: 100,
    position: 'relative',
    top: -3,
    height: '31px !important',
    marginLeft: 8,
    boxShadow: 'none',
    [theme.breakpoints.down('sm')]: {
      top: -1,
      boxShadow: 'none !important',
      fontSize: '.8rem',
      padding: '4px !important',
      width: '28px !important',
      height: '28px !important',
      minWidth: '28px !important',
      margin: '0 8px 0 0'
    }
  },
  bulkEditButton: {
    backgroundColor: 'transparent',
    color: energy,
    boxShadow: 'none',
    paddingRight: '5px !important',
    width: 25,
    height: 25,
    '&>span>svg': {
      fontSize: 24
    },
    '&:hover': {
      backgroundColor: 'transparent !important'
    },
    '&:active': {
      boxShadow: 'none !important'
    }
  },
  bulkEditingButton: {
    width: 130,
    height: 35
  },
  bulkEditOptionsContainer: {
    border: `1px solid ${lightGray}`,
    background: white,
    zIndex: 100,
    padding: 20,
    position: 'fixed',
    bottom: 20,
    left: '50%',
    marginLeft: -150,
    width: 310,
    [theme.breakpoints.down('sm')]: {
      width: 180,
      left: '72%'
    }
  },
  bulkEditOptionsContainerLiveOnly: {
    bottom: 70
  },
  bulkIcon: {
    position: 'relative',
    top: -1
  },
  plusMenu: {
    borderRadius: 20,
    backgroundColor: energy,
    color: white
  },
  quickPaper: {
    borderRadius: 5,
    backgroundColor: energy,
    color: `${white} !important`
  },
  menuItem: {
    color: white,
    height: '14px',
    padding: '10px 15px'
  },
  quickMenuIcon: {
    fontSize: 20,
    marginRight: 3
  },
  logoContainer: {
    padding: '10px !important',
    [theme.breakpoints.down('xs')]: {
      paddingLeft: '0px !important'
    }
  },
  logo: {
    width: '250px',
    paddingTop: 12
  },
  logoLogin: {
    width: 250,
    [theme.breakpoints.down('xs')]: {
      width: 175
    }
  },
  logoMediaViewer: {
    height: 50,
    paddingTop: 3,
    cursor: 'pointer'
  },
  menuMainContainer: {
    [theme.breakpoints.down('md')]: {
      paddingTop: 5
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: 10
    }
  },
  menuMainContainerLiveOnly: {
    margin: 0,
    paddingTop: 1,
    height: '100%',
    alignItems: 'center'
  },
  logoIcon: {
    position: 'relative',
    top: 1,
    width: 45,
    [theme.breakpoints.down('md')]: {
      top: 2,
      width: 35
    }
  },
  logoIconAppUserExtraSmall: {
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    }
  },
  liveOnlylogoIcon: {
    position: 'relative',
    top: 3,
    width: 100,
    [theme.breakpoints.down('sm')]: {
      top: 1
    }
  },
  menuMarginRight: {
    marginRight: 10
  },
  menuMarginRightSubNav: {
    marginRight: 10,
    width: 45
  },
  marginRightSmall: {
    marginRight: 5
  },
  padRight: {
    paddingRight: '10px !important'
  },
  borderRight: {
    borderRight: `1px solid ${menuDivider}`,
    marginRight: '15px'
  },
  borderLeft: {
    borderLeft: `1px solid ${menuDivider}`,
    paddingLeft: '20px !important',
    [theme.breakpoints.down('md')]: {
      paddingLeft: '15px !important'
    }
  },
  menuDrawerContainer: {
    zIndex: '13000 !important'
  },
  menuDrawer: {
    width: 250,
    background: black,
    padding: '50px 0',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  leftBarDrawer: {
    top: 76,
    height: 'calc(100% - 76px)',
    width: 250,
    padding: '25px 0'
  },
  leftBarDrawerLiveOnly: {
    top: 70,
    height: 'calc(100% - 70px)',
    width: 250,
    padding: '25px 0'
  },
  leftBarArea: {
    width: 40,
    flexBasis: 40
  },
  leftBarOpenIcon: {
    padding: 2,
    '&>span': {
      flexDirection: 'column'
    },
    '&>span>svg': {
      fontSize: 35
    }
  },
  verticalDrawerName: {
    writingMode: 'vertical-rl',
    paddingTop: 5
  },
  rightBarCloseIcon: {
    position: 'absolute',
    top: 5,
    right: 5,
    zIndex: 10,
    padding: 2,
    color: notBlack,
    '&>span>svg': {
      fontSize: 35
    }
  },
  rightBarArea: {
    position: 'relative',
    height: '100%',
    width: 'calc(100% - 50px)',
    flexBasis: 'calc(100% - 50px)'
  },
  menuButton: {
    display: 'inline-block',
    position: 'relative',
    marginRight: '8px',
    minWidth: 'initial',
    minHeight: 'initial',
    padding: '5px 10px 1px',
    color: companyText,
    fontSize: '1.25em',
    transition: 'background-color .15s, transform .35s',
    borderRadius: 0,
    '&:first-of-type': {
      paddingLeft: 5
    },
    '&::after': {
      content: "''",
      display: 'block',
      width: '100%',
      transform: 'scaleX(0)',
      transition: 'transform 0.2s',
      borderBottom: `5px solid ${torquoise}`,
      position: 'relative',
      top: 25
    },
    '&:hover': {
      backgroundColor: 'transparent',
      color: white,
      // transform: 'scale(1.15)'
    }
  },
  menuButtonAppUser: {
    '&::after': {
      top: 14
    },
    [theme.breakpoints.down('xs')]: {
      '&::after': {
        top: 16
      }
    }
  },
  menuButtonDrawer: {
    display: 'block',
    width: '100%',
    paddingLeft: 25,
    '&:first-of-type': {
      paddingLeft: 25
    },
    '&::after': {
      display: 'none'
    }
  },
  menuButtonExtended: {
    '&::after': {
      top: 19
    }
  },
  activeMenuButton: {
    // backgroundColor: `${opaqueBackground} !important`,
    // border: `1px solid ${white}`,
    // borderBottom: `3px solid ${torquoise}`,
    borderRadius: 0,
    color: white,
    // transform: 'scale(1.15)',
    '&::after': {
      transform: 'scaleX(1)'
    }
  },
  activeMenuButtonDrawer: {
    backgroundColor: torquoise,
    borderRadius: 0,
    '&:hover': {
      backgroundColor: torquoise,
      borderRadius: 0
    }
  },
  profileHeaderContainer: {
    '&::after': {
      content: "''",
      position: 'relative',
      top: 13,
      display: 'block',
      width: '100%',
      transform: 'scaleX(0)',
      transition: 'transform 0.2s',
      borderBottom: `5px solid ${torquoise}`
    },
    [theme.breakpoints.down('sm')]: {
      width: '83%',
      flexBasis: '83%',
      paddingTop: '20px !important',
      '&::after': {
        display: 'none'
      }
    }
  },
  activeProfileHeaderContainer: {
    '&::after': {
      transform: 'scaleX(1)'
    }
  },
  hideUserName: {
    display: 'none'
  },
  headerSettingsMenu: {
    [theme.breakpoints.down('md')]: {
      zIndex: '13000 !important'
    }
  },
  myIconContainer: {
    padding: '0 0 0 15px',
    marginRight: '10px'
  },
  myIcon: {
    width: '23px'
  },
  subMenu: {
    display: 'block',
    float: 'left',
    color: verbDarkHeading,
    padding: '0 5px 0',
    fontSize: 15,
    fontWeight: '300',
    // borderRight: `1px solid ${menuDivider}`,
    // transition: 'padding 0.15s',
    '&:last-of-type': {
      border: 'none'
    },
    '&:first-of-type': {
      paddingLeft: 0
    },
    '&>a': {
      display: 'block',
      float: 'left',
      top: 0,
      padding: '5px 10px 6px',
      // transition: 'transform .45s',
      '&:hover': {
        color: torquoise,
        // transform: 'scale(1.20)'
      }
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 14
    }
  },
  subMenuActive: {
    // position: 'relative',
    // top: -2,
    color: white,
    // padding: '0 25px 0',
    '&>a': {
      // transform: 'scale(1.20)',
      backgroundColor: `${torquoise} !important`,
      borderRadius: 20,
      '&:hover': {
        color: white
      }
    }
  },
  subNavMenuArrow: {
    position: 'absolute',
    bottom: 9,
    cursor: 'pointer',
    color: white,
    backgroundColor: notBlack,
    fontSize: 28,
    borderRadius: 50,
    width: '.8em',
    height: '.8em'
  },
  subNavMenuArrowDeactivated: {
    cursor: 'default',
    backgroundColor: gray
  },
  userContainer: {
    marginTop: '3px',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'flex-start',
      position: 'absolute',
      bottom: 30,
      width: '100%',
      margin: 0,
      paddingLeft: 20
    }
  },
  userContainerLiveOnly: {
    height: '100%',
    margin: 0,
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  moveDown: {
    position: 'relative',
    top: '3px'
  },
  moveDownHeaderName: {
    position: 'relative',
    top: -1,
    paddingRight: '0px !important'
  },
  userName: {
    display: 'inline',
    color: white,
    fontSize: '14px',
    lineHeight: '20px',
    cursor: 'pointer',
    textTransform: 'uppercase',
    [theme.breakpoints.down(1250)]: {
      fontSize: '14px'
    }
  },
  userCompany: {
    color: companyText,
    fontSize: '14px',
    lineHeight: '18px',
    paddingLeft: '1px',
    cursor: 'pointer'
  },
  onlyUserCompanyName: {
    fontSize: 16,
    paddingBottom: 3
  },
  userDrop: {
    display: 'inline',
    position: 'relative',
    top: -2,
    marginRight: 0,
    borderRadius: '0px',
    color: companyText,
    '&>span>svg': {
      fontSize: 22
    },
    [theme.breakpoints.down('sm')]: {
      top: 17,
      '&>span>svg': {
        fontSize: 32
      }
    }
  },
  userDropLiveOnly: {
    top: 0,
    [theme.breakpoints.down('sm')]: {
      top: 0,
      '&>span>svg': {
        fontSize: 22
      }
    }
  },
  userIconContainer: {
    display: 'inline',
    marginLeft: '20px'
  },
  userIcon: {
    color: companyText,
    width: '45px',
    height: '20px'
  },
  //END HEADER

  footer: {
    position: 'fixed',
    height: '50px',
    // borderTop: `${darkTorquoise} 5px solid`,
    backgroundImage: 'url(/images/solofire-background.png)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    left: 0,
    bottom: 0,
    width: '100%',
    color: companyText,
    textAlign: 'center'
  },
  headingBig: {
    fontSize: 24,
    textAlign: 'center'
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    marginBottom: 16,
    fontSize: 14,
    color: theme.palette.text.secondary,
  },
  pos: {
    marginBottom: 12,
    color: theme.palette.text.secondary,
  },
  loginContainer: {
    margin: '15% auto 0',
    padding: 40,
    width: 'calc(90%)',
    maxWidth: 900,
    borderRadius: 8,
    backgroundColor: white,
    [theme.breakpoints.down('sm')]: {
      margin: '0 auto',
      padding: 20,
      width: '96%',
      maxWidth: 450
    }
  },
  loginForm: {
    maxHeight: '100%'
  },
  loginButton: {
    width: '100%',
    marginTop: 20,
    height: 40
  },
  verbLogin: {
    width: '100%',
    position: 'relative',
    top: '46%',
    transform: 'translateY(-50%)'
  },
  createAccountButton: {
    maxWidth: 250,
    backgroundColor: verbSeaGreen, 
    fontFamily: 'Gotham',
    fontSize: 18,
    color: white,
    '&:hover': {
      backgroundColor: verbSeaGreen
    }
  },
  createAccountInfo: {
    position: 'relative',
    borderRadius: 5,
    height: '100%',
    margin: '0 auto',
    textAlign: 'center'
  },
  createSolofireAccountInfo: {
    backgroundColor: darkBlue
  },
  createAccountContainerWrapper: {
    position: 'relative',
    top: -1,
    background: 'rgb(0,95,209)',
    background: 'linear-gradient(135deg, rgba(0,95,209,1) 10%, rgba(0,207,227,1) 100%)'
  },
  createAccountContainer: {
    maxWidth: 1200,
    margin: '85px auto 0',
    padding: '70px 50px 80px',
    [theme.breakpoints.down('sm')]: {
      padding: '60px 40px 70px'
    }
  },
  createAccountRoot: {
    height: 'initial',
    maxWidth: 'initial',
    margin: 0,
    padding: 0
  },
  createAccountImage: {
    textAlign: 'center',
    height: 250,
    padding: '10px 0'
  },
  createAccountH2: {
    textAlign: 'center',
    color: teal,
    fontSize: 24,
    padding: '20px 0 0'
  },
  createAccountH4: {
    textAlign: 'center',
    color: white,
    fontSize: 20
  },
  createAccountHeading: {
    color: torquoise,
    fontSize: 38,
    fontWeight: 600,
    fontFamily: 'Gotham'
  },
  createAccountHeading2: {
    color: darkBlue,
    fontSize: 30,
    fontWeight: 300
  },
  createAccountHeading3: {
    color: storm,
    fontSize: 38,
    fontFamily: 'Gotham',
    fontWeight: 600,
    paddingBottom: 20,
    lineHeight: '40px',
    [theme.breakpoints.down('sm')]: {
      fontSize: 34
    }
  },
  createAccountHeadingCenter: {
    color: energy,
    fontSize: 35,
    textAlign: 'center'
  },
  createAccountHeading3Navy: {
    color: navy,
    fontSize: 36,
    paddingBottom: 5
  },
  createAccountVerbHeading: {
    fontFamily: 'Gotham',
    fontWeight: 600,
    fontSize: 26,
    lineHeight: '35px',
    color: black,
    paddingBottom: 10
  },
  textfieldWhiteBorder: {
    borderColor: `${white} !important`,
    borderRadius: 2
  },
  createAccountBody: {
    fontFamily: 'Gotham',
    fontWeight: 300,
    color: black,
    fontSize: 14,
    lineHeight: '25px',
    textAlign: 'left'
  },
  createAccountBodyAzureMist: {
    color: azureMist,
    fontSize: 28,
    paddingBottom: 20
  },
  createAccountBodyCentered: {
    textAlign: 'center'
  },
  createAccountSectionWrapper: {
    width: '100%'
  },
  createAccountSectionWrapperLightGray: {
    backgroundColor: evenLighterGray
  },
  createAccountSectionWrapperTorquoise: {
    background: 'rgb(0,95,209)',
    background: 'linear-gradient(135deg, rgba(0,95,209,1) 10%, rgba(0,207,227,1) 100%)'
  },
  createAccountSection: {
    maxWidth: 1200,
    margin: '0 auto',
    padding: '50px 0',
    [theme.breakpoints.down('md')]: {
      padding: '50px'
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: '35px 20px'
    }
  },
  createAccountSectionHow: {
    [theme.breakpoints.down('sm')]: {
      margin: 0,
      padding: '50px 30px'
    }
  },
  createAccountSectionBottom: {
    maxWidth: 600
  },
  nonMobileSection: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  mobileSection: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'block'
    }
  },
  createAccountSectionImage: {
    width: '100%',
    margin: '0 auto',
    maxWidth: 400
  },
  createAccountSectionImagePeople: {
    position: 'relative', 
    top: 90,
    [theme.breakpoints.down('sm')]: {
      top: 45
    },
  },
  showcaseGateContainer: {
    position: 'absolute',
    zIndex: 10000,
    backgroundColor: 'rgba(1,22,39, .7)',
    width: '100%',
    height: '100%' 
  },
  leadFormContainer: {
    margin: '12% auto 0',
    padding: 30,
    width: 375,
    backgroundColor: white,
    borderRadius: 20,
    [theme.breakpoints.down(400)]: {
      margin: '30% auto 0',
      width: '96%'
    }
  },
  textField: {
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
    width: 200,
  },
  buttonWide: {
    width: '100%',
    marginTop: 20,
    float: 'right'
  },
  verticalDots: {
    color: dolphin,
    fontWeight: '300',
    border: `1px solid ${lightGray}`,
    borderRadius: '50%',
    '&:hover': {
      color: energy,
      borderColor: energy
    }
  },
  verticalDotsSmall: {
    fontSize: '18px'
  },
  verticalDotsBig: {
    fontSize: '21px'
  },
  verticalDotsAppProfile: {
    borderColor: energy,
    fontSize: '22px',
    color: energy,
    marginTop: 5
  },
  tableToolbar: {
    marginTop: 10
  },
  tableHeader: {
    background: 'none'
  },
  standardCell: {
    padding: '6px 8px'
  },
  analyticsTableTitle: {
    float: 'left',
    fontWeight: 300,
    paddingRight: 5
  },
  analyticsTableTitleText: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  appWebTitle: {
    fontWeight: 300,
    fontSize: '.9rem'
  },
  headerTopBorder: {
    height: 60,
    borderBottom: 'none',
    '&:first-of-type': {
      borderTop: `1px solid ${darkPeacock}`
    }
  },
  dividerCell: {
    borderRight: `1px solid ${darkPeacock} !important`
  },
  afterCellHead: {
    paddingLeft: '15px !important'
  },
  afterCell: {
    paddingLeft: '20px !important'
  },
  separationRow: {
    height: 48,
    '&:first-of-type': {
      borderTop: 'none'
    }
  },
  noBorder: {
    border: 'none !important',
    padding: '3px 8px'
  },
  noCursor: {
    cursor: 'default'
  },
  shareCell: {
    // position: 'sticky',
    // top: 0,
    // left: 0,
    // zIndex: 10,
    // backgroundColor: white,
    '&>div>div>div>span>svg': {
      display: 'none'
    }
  },
  menuCell: {
    position: 'sticky',
    right: 0,
    zIndex: 10,
    backgroundColor: white,
    textAlign: 'right',
    padding: '0 10px 0 0 !important',
    '&>div': {
      justifyContent: 'flex-end'
    }
  },
  cellThumbnail: {
    maxWidth: '100%',
    maxHeight: 52,
    verticalAlign: 'middle'
  },
  shareButton: {
    backgroundColor: 'none',
    border: `1px solid ${lightGray}`,
    minWidth: '50px',
    minHeight: '22px',
    padding: '2px 8px',
    fontSize: '13px',
    fontWeight: '300',
    color: dolphin
  },
  shareButtonLabel: {
    lineHeight: '1rem'
  },
  addButton: {
    minHeight: 'initial',
    color: energy,
    padding: '2px 8px',
    fontSize: '14px',
    border: 'none',
    backgroundColor: white,
    '&:hover': {
      backgroundColor: energy,
      color: white
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 13
    }
  },
  plusButtonButton: {
    minHeight: 'initial',
    color: white,
    padding: '2px 8px 2px 0',
    fontSize: '16px',
    border: 'none',
    backgroundColor: 'initial',
    '&:hover': {
      backgroundColor: 'initial'
    }
  },
  addButtonBrandingReset: {
    top: 26,
    marginLeft: 10,
    [theme.breakpoints.down('xs')]: {
      top: 0
    }
  },
  teamAddButtonBrandingReset: {
    top: 10
  },
  addButtonModal: {
    position: 'relative',
    top: -2,
    marginLeft: 10
  },
  addButtonUser: {
    position: 'relative',
    top: 4
  },
  addButtonReset: {
    zIndex: 10,
    margin: 0,
    position: 'absolute',
    right: 360,
    top: 5
  },
  addButtonResetModal: {
    top: 78,
    right: 385
  },
  addButtonResetViewModal: {
    top: 83,
    right: 385
  },
  addButtonResetViewAppProfileTeamsModal: {
    top: 20,
    right: 50
  },
  addButtonDown: {
    position: 'relative',
    top: -8,
    left: -15
  },
  addButtonDownTeam: {
    position: 'relative',
    top: 11,
    left: -19
  },
  addButtonLeftMargin: {
    marginLeft: 10
  },
  addButtonLeftMarginTop: {
    marginLeft: 10,
    top: -2
  },
  addMembersButton: {
    position: 'absolute',
    top: 60,
    left: 20
  },
  tabButtonButton: {
    position: 'absolute',
    top: 0,
    left: 2
  },
  addButtonLibraryContent: {
    fontFamily: '"Gotham", "Helvetica", "Arial", sans-serif',
    borderRadius: 20,
    padding: 0,
    lineHeight: 0,
    cursor: 'pointer'
  },
  addButtonLibraryContentText: {
    lineHeight: '20px',
    padding: '0 10px 5px 9px'
  },
  addButtonBorder: {
    border: `1px solid ${energy}`
  },
  rightButton: {
    float: 'right'
  },
  contentGroupsHeading: {
    paddingLeft: 16,
    fontSize: '1.1rem',
    fontWeight: 300,
    color: peacock,
    borderBottom: `1px solid ${beforeLightGray}`,
    [theme.breakpoints.down('md')]: {
      fontSize: '1rem'
    }
  },
  myFilesHeading: {
    marginTop: 20
  },
  libraryGroupsColumn: {
    maxHeight: '25%',
    overflowY: 'scroll'
  },
  addLibraryGroup: {
    marginLeft: 5,
    position: 'relative',
    top: -5
  },
  addPersonalLibraryGroup: {
    position: 'relative',
    left: -10,
    marginBottom: 5
  },
  previewEditItem: {
    float: 'right',
    position: 'absolute',
    top: 14,
    right: 50,
    padding: '0 10px 1px 8px'
  },
  showcasePreviewButton: {
    top: 8,
    right: 45
  },
  libraryGroupListMargin: {
    height: 'calc(100vh - 165px)',
    overflowY: 'auto',
    // '&>li:nth-of-type(2)': {
    //   borderWidth: 0
    // }
    [theme.breakpoints.down('sm')]: {
      paddingTop: 20
    }
  },
  libraryGroupItem: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    height: 37
  },
  openLibraryGroupBackground: {
    backgroundColor: evenLighterGray
  },
  libraryGroupItemText: {
    display: 'flex',
    width: 'calc(100% - 18px)',
    padding: '0 10px'
  },
  subGroupText: {
    position: 'relative',
    top: -1,
    fontSize: '1rem !important',
    fontWeight: 300
  },
  teamItemText: {
    display: 'inline-block',
    width: 'calc(100% - 16px)',
    padding: '0 10px'
  },
  addCircleIcon: {
    width: '20px',
    height: '20px',
    marginRight: '5px'
  },
  addCircleIconReset: {
    width: '20px',
    height: '20px',
    marginLeft: '5px'
  },
  addCircleIconLibraryContent: {
    position: 'relative',
    top: 5
  },
  fullLibraryIcon: {
    position: 'relative',
    left: -2,
    marginRight: -6,
    '&>svg': {
      fontSize: 22
    }
  },
  listMainHeaders: {
    padding: '2px 15px 2px 16px',
    // borderTop: `1px solid ${lightGray}`
  },
  fullLibraryItem: {
    marginBottom: 25,
    borderBottom: `1px solid ${lightGray}`
  },
  fullShowcases: {
    marginTop: 25
  },
  libraryGroupIcon: {
    display: 'flex',
    marginRight: 0,
    '&>svg': {
      fontSize: 16,
      // position: 'relative',
      // top: -3
    }
  },
  librarySubGroupIcon: {
    // '&>svg': {
    //   top: -4
    // }
  },
  noItemBorder: {
    borderStyle: 'none'
  },
  bigLeftMenuText: {
    fontSize: '1.05rem !important',
    [theme.breakpoints.down(1150)]: {
      position: 'relative',
      top: 1,
      fontSize: '.90rem !important'
    }
  },
  selectedBigLeftMenuText: {
    fontSize: '1.05rem !important',
    color: `${white} !important`,
    [theme.breakpoints.down(1150)]: {
      position: 'relative',
      top: 1,
      fontSize: '.90rem !important'
    }
  },
  selectedItem: {
    backgroundColor: torquoise,
    // borderColor: torquoise,
    color: white,
    '&:hover': {
      backgroundColor: torquoise,
      color: white,
    }
  },
  selectedItemText: {
    color: `${white} !important`
  },
  selectedVerticalDots: {
    color: white,
    borderColor: white,
  },
  modal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 'calc(100% - 5%)',
    height: 'calc(100% - 5%)',
    maxWidth: 1450,
    maxHeight: 1000,
    backgroundColor: white,
    padding: '25px 30px',
    border: `1px solid ${white}`,
    borderRadius: '20px',
    WebkitTapHighlightColor: highlight,
    outline: 'none !important',
    '&:focus': {
      outline: 'none !important'
    },
    [theme.breakpoints.down('sm')]: {
      padding: '15px 20px'
    },
    '&>div>h6': {
      paddingRight: 15
    }
  },
  fullModal: {
    maxHeight: 'initial',
    maxWidth: 1650
  },
  fullModalAnalytics: {
    maxHeight: 'initial',
    maxWidth: 1650,
    width: 'calc(100% - 30px)',
    height: 'calc(100% - 30px)'
  },
  previewModal: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 15,
    width: 'calc(100% - 2%)',
    height: 'calc(100% - 2%)',
    maxWidth: 'initial',
    outline: 'none !important',
    [theme.breakpoints.down('sm')]: {
      padding: 7
    }
  },
  mediumModal: {
    height: 'calc(100% - 10%)',
    maxWidth: 1200,
    [theme.breakpoints.down('md')]: {
      padding: 15,
      maxHeight: 'initial',
      width: 'calc(100% - 2%)',
      height: 'calc(100% - 2%)'
    }
  },
  mediumMediumModal: {
    maxWidth: 1000,
    maxHeight: 1000
  },
  smallMediumModal: {
    maxWidth: 1000,
    maxHeight: 750
  },
  smallModal: {
    maxWidth: 700,
    maxHeight: 600
  },
  bulkUsersDeleteModal: {
    maxWidth: 650,
    maxHeight: 400
  },
  userDeleteModal: {
    maxWidth: 650,
    maxHeight: 300
  },
  deleteModal: {
    maxWidth: 650,
    maxHeight: 250
  },
  modalSubheading: {
    fontSize: '.85rem',
    maxWidth: '90%',
    margin: '3px 0 15px'
  },
  modalPreviewSubheading: {
    marginBottom: 5
  },
  modalSubheadingTable: {
    position: 'absolute',
    top: 51
  },
  modalSubheadingTableAssignToTeams: {
    position: 'absolute',
    top: 17,
    marginLeft: 10,
    fontSize: '1.15rem',
    color: peacock
  },
  noteSubheading: {
    fontSize: '1rem',
    margin: '15px 25px 0',
    textAlign: 'center'
  },
  modalSubheadingDelete: {
    marginTop: 30,
    lineHeight: '30px',
    textAlign: 'center',
    fontSize: '1.3rem',
    fontWeight: 300
  },
  modalSubheadingDeleteSmall: {
    lineHeight: '40px',
    textAlign: 'center',
    fontSize: '1.1rem',
    fontWeight: 300
  },
  subheadingUpload: {
    margin: '10px 0 0',
    fontSize: '.85rem',
    textAlign: 'center'
  },
  popoverSubheading: {
    marginBottom: 0,
    fontSize: '.85rem',
    textAlign: 'center',
    color: dolphin
  },
  iconClose: {
    position: 'absolute',
    top: 5,
    right: 5,
    zIndex: 10,
    padding: 5,
    color: dolphin,
    '&>span>svg': {
      fontSize: 25,
      [theme.breakpoints.down('sm')]: {
        fontSize: 22
      }
    }
  },
  iconCloseShareCart: {
    position: 'absolute',
    color: white,
    top: -5,
    right: 5,
    '&>span>svg': {
      fontSize: 18
    }
  },
  mobileIconClose: {
    top: 7,
    right: 7,
    [theme.breakpoints.down('sm')]: {
      top: 0,
      right: 0
    }
  },
  iconRight: {
    float: 'right',
    color: dolphin,
    height: 30,
    width: 30
  },
  button: {
    width: '100%',
    maxWidth: '150px',
    marginTop: 20,
    float: 'right'
  },
  linkButton: {
    marginTop: 15,
    borderRadius: 35,
    width: '95%',
    maxWidth: 600,
    fontSize: 30,
    [theme.breakpoints.down('xs')]: {
      fontSize: 20
    }
  },
  goLiveLink: {
    display: 'block',
    textAlign: 'center'
  },
  goLiveButton: {
    marginTop: 30,
    borderRadius: 25,
    fontSize: 25,
    width: 300
  },
  goLiveHeaderButton: {
    marginLeft: 20,
    padding: '5px 16px',
    [theme.breakpoints.down(1320)]: {
      fontSize: 12,
      padding: '3px 14px'
    },
    [theme.breakpoints.down('md')]: {
      position: 'relative',
      top: 2,
      marginLeft: 20
    },
    [theme.breakpoints.down('xs')]: {
      minWidth: 50,
      padding: '2px 11px'
    },
    [theme.breakpoints.down(375)]: {
      minWidth: 40
    },
    [theme.breakpoints.down(365)]: {
      fontSize: 11
    }
  },
  shareHeaderButton: {
    marginLeft: 0,
    [theme.breakpoints.down('xs')]: {
      marginLeft: 0
    }
  },
  blueHeaderButton: {
    backgroundColor: verbSecondary,
    '&:hover': {
      backgroundColor: verbDarkerBlue
    }
  },
  shareSpaceHeaderButton: {
    border: `1px solid ${white}`,
    backgroundColor: notBlack,
    '&:hover': {
      backgroundColor: '#000000'
    }
  },
  videoCamButton: {
    paddingRight: 5,
    fontSize: 25,
    [theme.breakpoints.down('xs')]: {
      position: 'relative',
      top: 1,
      fontSize: 18,
      paddingRight: 3
    },
    [theme.breakpoints.down(365)]: {
      top: 0
    }
  },
  videoCamButtonNotLiveOnly: {
    // [theme.breakpoints.down('xs')]: {
    //   top: 0,
    //   paddingRight: 0
    // }
  },
  cancelInteractiveVideo: {
    marginLeft: 10,
    fontSize: 15,
    cursor: 'pointer',
    color: verbPurple
  },
  resetButton: {
    maxWidth: 'initial',
    width: 225,
    float: 'none'
  },
  confirmResetPassword: {
    marginTop: 0
  },
  modalButton: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    margin: '0 20px 20px 0',
    // [theme.breakpoints.down('xs')]: {
    //   position: 'relative',
    //   marginTop: 30
    // }
  },
  brandSubmit: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    margin: '0 20px 20px 0',
    [theme.breakpoints.down('xs')]: {
      position: 'relative'
    }
  },
  shareModalButton: {
    marginTop: 30
  },
  italic: {
    fontStyle: 'italic'
  },
  tagInputContainer: {
    height: 100,
    marginBottom: 0,
    [theme.breakpoints.down('sm')]: {
      height: 125
    }
    // border: `1px solid ${dolphin}`,
    // borderRadius: 4
  },
  tagContactInputContainer: {
    height: 200
  },
  tagsContainer: {
    display: 'block',
    position: 'relative',
    flexDirection: 'column',
    marginTop: 22,
    height: 80,
    flexWrap: 'wrap',
    width: '100%',
    overflowY: 'scroll'
  },
  tagsContactContainer: {
    height: 180
  },
  tag: {
    '&>svg' : {
      backgroundColor: peacock,
      color: white,
      '&:hover': {
        backgroundColor: peacock,
        color: white
      }
    }
  },
  libraryTags: {
    marginTop: 5,
    fontSize: 13,
    fontWeight: 300,
    color: dolphin
  },
  libraryFilename: {
    fontSize: 13,
    fontWeight: 200
  },
  inputContainer: {
    margin: 5
  },
  inputContactContainer: {
    width: 250
  },
  headlineTorquoise: {
    color: torquoise
  },
  buttonMarginTop: {
    marginTop: 30
  },
  buttonMarginBottom: {
    marginBottom: 30
  },
  containerPaddingLeft: {
    paddingLeft: '2% !important'
  },
  applyAll: {
    width: 285,
    margin: '0 5px 60px 0',
    position: 'absolute',
    bottom: 0,
    right: 0
  },
  gridNoSideMargins: {
    marginLeft: 0,
    marginRight: 0,
    width: '100%'
  },
  gridNoPadding: {
    padding: '0px !important'
  },
  gridExtraSpacing: {
    padding: '10px 20px 12px !important'
  },
  gridTagsSpacing: {
    padding: '0 20px 12px !important'
  },
  gridBorderRight: {
    borderRight: `1px solid ${lightDolphin}`
  },
  gridItemLimitHeight: {
    height: '100%'
  },
  gridNoPaddingTop: {
    paddingTop: '0px !important'
  },
  emailAlternateContentLabel: {
    marginLeft: 30
  },
  addAlternateContentButton: {
    position: 'relative',
    top: -2,
    padding: '2px 6px',
    margin: '0 0 0 10px',
    minWidth: 75,
    float: 'right'
  },
  altEmailContentName: {
    marginLeft: 40
  },
  uploadButton: {
    width: '100%',
    minWidth: 160,
    maxWidth: 215,
    marginLeft: 2,
    border: `1px solid ${energy}`,
    borderRadius: 20,
    backgroundColor: white,
    fontFamily: '"Gotham", "Helvetica", "Arial", sans-serif',
    color: energy,
    padding: '0 12px 0 10px',
    fontSize: 12,
    lineHeight: '28px',
    cursor: 'pointer',
    textAlign: 'center',
    '&:hover': {
      backgroundColor: energy,
      color: white
    }
  },
  uploadBrandingButton: {
    margin: '0 auto'
  },
  uploadOnboardingButton: {
    backgroundColor: energy,
    color: white,
    '&:hover': {
      backgroundColor: darkEnergy
    }
  },
  uploadAppProfileButton: {
    margin: '0 auto'
  },
  uploadButtonIcon: {
    position: 'relative',
    top: 6
  },
  centerInfoText: {
    textAlign: 'center',
    fontSize: '1rem',
    color: torquoise
  },
  centerErrorText: {
    color: helperText
  },
  centerBigErrorText: {
    textAlign: 'center',
    color: helperText,
    fontSize: 18,
    marginBottom: 5
  },
  bigError: {
    fontSize: '1rem',
    textAlign: 'left',
    marginTop: 0
  },
  formError: {
    fontSize: '1rem',
    textAlign: 'left',
    marginBottom: 15
  },
  thumbContainer: {
    position: 'relative',
    width: '90%',
    height: 400,
    margin: '0 auto',
    padding: 10,
    border: `1px solid ${lightGray}`,
    borderRadius: 15
  },
  smallThumbContainer: {
    height: 125
  },
  extraThumbContainerClass: {
    height: 250
  },
  webBackgroundImageContainer: {
    backgroundColor: notBlack,
    backgroundImage: 'url(/images/verb/default_background.png)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: '50% 50%'
  },
  webLogoImageContainer: {
    backgroundColor: notBlack,
    backgroundImage: 'url(/images/verb/verb-bug.png)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '27% auto',
    backgroundPosition: '50% 50%'
  },
  webLogoContainer: {
    backgroundColor: notBlack
  },
  libraryThumbnail: {
    display: 'block',
    width: 'auto',
    maxHeight: 150,
    maxWidth: '100%',
    marginTop: 10,
    cursor: 'pointer'
  },
  tradeBoothThumbnailContainer: {
    textAlign: 'center',
    margin: '0 auto'
  },
  tradeBoothNoThumbnailContainer: {
    width: 150,
    height: 150,
    border: `1px solid ${lightGray}`,
  },
  tradeBoothThumbnail: {
    textAlign: 'center',
    margin: '0 auto',
    maxWidth: 150,
    maxHeight: 150,
    border: `1px solid ${lightGray}`
  },
  tradeBoothThumbnailUploadContainer: {
    textAlign: 'center',
    margin: '0 auto'
  },
  teamBrandHeading: {
    float: 'left',
    fontSize: '2.2rem'
  },
  brandingThumbnail: {
    margin: '0 auto',
    position: 'relative',
    top: '50%',
    transform: 'translateY(-50%)',
    height: 'auto',
    maxHeight: 115
  },
  brandingBackgroundThumbnail: {
    maxHeight: 380
  },
  extraBrandingBackgroundThumbnail: {
    maxHeight: 230
  },
  searchBar: {
    position: 'relative',
    height: 40,
    paddingTop: 5,
    textAlign: 'right',
    [theme.breakpoints.down('sm')]: {
      paddingTop: 0
    }
  },
  searchBarAnalytics: {
    top: 9,
    [theme.breakpoints.down('sm')]: {
      textAlign: 'left'
    }
  },
  searchIcon: {
    position: 'relative',
    top: 7,
    fontSize: 22,
    color: darkPeacock
  },
  closeIcon: {
    position: 'absolute',
    top: 5,
    right: 0,
    fontSize: '1.4rem',
    color: darkPeacock,
    cursor: 'pointer'
  },
  closeIconActivity: {
    top: -3
  },
  searchField: {
    width: 220,
    padding: '0 20px 0 8px',
    '&>input': {
      padding: '6px 0 5px',
      fontSize: 15
    },
    [theme.breakpoints.down('xs')]: {
      width: 175
    }
  },
  uploadedName: {
    padding: '5px 0 0 5px',
    color: torquoise
  },
  uploadedNameTradeBooth: {
    textAlign: 'center'
  },
  uploadedNameBranding: {
    textAlign: 'center'
  },
  previewModalHeader: {
    width: '100%',
    padding: '5px 20px 10px',
    // flexBasis: '100%',
    // maxHeight: '75px'
  },
  filePreviewContainer: {
    // height: 'calc(100% - 75px)',
    flexBasis: '100%',
    width: '100%',
    overflow: 'hidden',
    paddingLeft:  '15px 0px 15px 0px',
    textAlign: 'center',
    backgroundColor: notBlack,
    [theme.breakpoints.down('sm')]: {
      height: 'calc(100% - 95px)'
    },
    [theme.breakpoints.down('xs')]: {
      padding: 10
    }
  },
  filePreviewContainerFullscreen: {
    marginTop: 25
  },
  filePreviewContainerVideo: {
    height: 'calc(100% - 75px)'
  },
  mobileFilePreviewContainer: {
    height: 'calc(100% - 95px)'
  },
  videoIframe: {
    flexGrow: 1
  },
  filePreviewContainerInteractiveVideo: {
    position: 'relative',
    flexBasis: '100%',
    width: '100%',
    backgroundColor: white,
    textAlign: 'left',
    height: '100%',
    padding: 0,
    [theme.breakpoints.down('sm')]: {
      marginTop: 20
    }
  },
  interactiveVideoMainContainer: {
    display: 'flex',
    width: '100%',
    height: '100%',
    backgroundColor: white,
    flexDirection: 'column'
  },
  fromAppinteractiveVideoMainContainer: {
    padding: '110px 30px 30px'
  },
  interactiveVideoContainer: {
    height: '100%',
    overflow: 'auto',
    padding: '5px 0 10px',
    marginBottom: 10
  },
  extraInteractiveVideoContainer: {
    paddingTop: 0
  },
  convertedPPTPreviewText: {
    color: white,
    fontSize: 18,
    paddingBottom: 7,
    [theme.breakpoints.down('sm')]: {
      fontSize: 15
    }
  },
  convertedPPTPreview: {
    height: 'calc(100% - 30px)',
    width: '100%',
    [theme.breakpoints.down('md')]: {
      height: 'calc(100% - 60px)'
    },
    [theme.breakpoints.down('sm')]: {
      height: 'calc(100% - 90px)'
    }
  },
  filestackPreview: {
    height: '100%',
    width: '100%'
  },
  filestackPreviewVideoContainer: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  filestackPreviewVideo: {
    width: 'auto',
    height: 'auto',
    maxWidth: '100%',
    maxHeight: '100%'
  },
  imagePreview: {
    textAlign: 'center',
    height: '100%',
    width: 'auto',
    maxWidth: '100%'
  },
  filestackImagePreview: {
    maxHeight: '100%',
    width: 'auto',
    maxWidth: '100%'
  },
  editLinkContainer: {
    float: 'right',
    marginTop: 10,
    wordWrap: 'break-word',
    color: verbPurple
  },
  editLink: {
    fontSize: '1rem',
    color: peacock
  },
  gridSwitch: {
    display: 'flex'
  },
  uploadedItemsGridContainer: {
    [theme.breakpoints.down('md')]: {
      marginBottom: 30
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: 50
    }
  },
  uploadedItemsGridItem: {
    [theme.breakpoints.down('md')]: {
      padding: '0 16px !important'
    }
  },
  switchField: {
    marginLeft: 20,
    justifyContent: 'space-around'
  },
  editUploadedTextfield: {
    marginTop: 19,
    [theme.breakpoints.down('sm')]: {
      marginTop: 8
    }
  },
  editUploadedTagsfield: {
    marginTop: -3
  },
  tagsMarginTop: {
    marginTop: 20
  },
  usersHeading: {
    float: 'left',
    padding: '4px 10px'
  },
  usersGridReset: {
    marginBottom: 0
  },
  marginTop: {
    marginTop: 25,
  },
  noPaddingTop: {
    paddingTop: '0 !important'
  },
  noPaddingBottom: {
    padding: '12px 12px 0 12px !important'
  },
  lessGridPadding: {
    padding: '0 5px 5px !important'
  },
  solidFilestack: {
    margin: '20px 0 10px 5px',
    border: 'none',
    padding: 0,
    lineHeight: 0,
    fontSize: 14,
    backgroundColor: energy,
    color: white,
    '&:hover': {
      backgroundColor: darkEnergy
    }
  },
  borderFilestack: {
    margin: '20px 0 10px 5px',
    border: `1px solid ${energy}`,
    padding: 0,
    lineHeight: 0,
    fontSize: 14,
    backgroundColor: 'none',
    color: energy,
    '&:hover': {
      backgroundColor: energy,
      color: white
    }
  },
  borderFilestack360: {
    margin: '0 0 6px 5px'
  },
  updatedNewVersion: {
    marginLeft: '12px'
  },
  create: {
    float: 'left'
  },
  forgot: {
    float: 'right'
  },
  contentTitle: {
    marginBottom: 10
  },
  topButtonRow: {
    position: 'absolute',
    height: 35,
    lineHeight: '1.5em'
  },
  topButtonRowWithH3: {
    top: 195,
  },
  topButtonRowMedium: {
    height: 30
  },
  topButtonRowSmall: {
    height: 20
  },
  topButtonRowUsers: {
    marginLeft: 7
  },
  scrollArea: {
    height: 'calc(100% - 130px)',
    overflowY: 'auto',
    overflowX: 'hidden'
  },
  brandingHeading: {
    paddingBottom: 5
  },
  brandingContainer: {
    marginTop: 10
  },
  colorPickerRow: {
    height: 65
  },
  colorPickerContainer: {
    margin: '0 auto 15px',
    width: '95%'
  },
  colorPicker: {
    width: 30,
    height: 30,
    border: `1px solid ${lightGray}`,
    borderRadius: 20
  },
  colorPickerLabel: {
    margin: '0 auto',
    [theme.breakpoints.down('xs')]: {
      display: 'block',
      textAlign: 'center'
    }
  },
  swatchPicker: {
    position: 'relative',
    top: 2,
    marginRight: 10,
    padding: 5,
    display: 'inline-block',
    cursor: 'pointer',
    [theme.breakpoints.down('xs')]: {
      marginRight: 0
    }
  },
  popoverPicker: {
    position: 'absolute',
    top: 40,
    right: 24,
    zIndex: '2',
    [theme.breakpoints.down('sm')]: {
      top: 50,
      right: -60
    },
    [theme.breakpoints.down('xs')]: {
      position: 'fixed',
      top: 240,
      left: '35%'
    }
  },
  coverPicker: {
    position: 'fixed',
    top: '0px',
    right: '0px',
    bottom: '0px',
    left: '0px'
  },
  topModalOpenButton: {
    position: 'absolute',
    bottom: 22,
    left: 18
  },
  smallHeightSubheading: {
    position: 'relative',
    top: 0,
    lineHeight: '1.15rem'
  },
  modalTabBar: {
    marginBottom: 20,
    height: 40
  },
  modalTab: {
    height: 40
  },
  tagsTab: {
    marginLeft: 0
  },
  statsTab: {
    marginTop: 30
  },
  popoverIconButton: {
    width: 20,
    height: 20,
    position: 'relative',
    top: -2,
    left: 2,
    color: peacock
  },
  popoverIcon: {
    fontSize: 20
  },
  mediaViewerContainer: {
    margin: 20,
    position: 'relative',
    height: `calc(100vh - ${heightOffset} - 265px)`
  },
  mediaViewerTitle: {
    marginBottom: 10
  },
  gridList: {
    width: '100%',
    height: '100%',
    margin: '0 !important',
    // Promote the list into its own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)'
  },
  gridListBottom: {
    position: 'absolute',
    bottom: 0,
    flexWrap: 'nowrap'
  },
  gridListTile: {
    cursor: 'pointer',
    margin: '5px !important',
    height: '225px !important',
    width: 'calc(25% - 10px) !important',
    borderColor: torquoise,
    '&:hover': {
      borderWidth: 5,
      borderStyle: 'solid',
    }
  },
  gridListTileBottom: {
    height: '150px !important',
    margin: '2px !important',
    width: 'calc(20% - 6px) !important',
  },
  selectedGridListTile: {
    borderWidth: 5,
    borderStyle: 'solid'
  },
  titleText: {
    color: white,
  },
  titleBar: {
    background: peacock,
    height: 35
  },
  dashboardFormWrapper: {
    position: 'fixed',
    top: 124,
    left: 0,
    width: '100%',
    backgroundColor: white,
    zIndex: 1000,
    [theme.breakpoints.down('md')]: {
      position: 'relative',
      top: 0,
      zIndex: 100
    },
    [theme.breakpoints.down('sm')]: {
      paddingBottom: 20
    }
  },
  dashboardFormContainer: {
    fontFamily: '"Gotham", "Helvetica", "Arial", sans-serif',
    position: 'relative',
    margin: '0 auto',
    padding: '15px 2.5% 5px',
    width: '100%',
    maxWidth: 1650,
    height: 90,
    [theme.breakpoints.down('sm')]: {
      height: '100%'
    }
  },
  dashboardFormInsideContainer: {
    margin: '10px 0 0 0'
  },
  dashboardForm: {
    overflowY: 'visible',
    overflowX: 'visible',
    maxHeight: 'initial'
  },
  dropdownDashboard: {
    position: 'relative',
    top: -6
  },
  dashboardFormItem: {
    float: 'left',
    padding: '0 24px 0 0',
    [theme.breakpoints.down('md')]: {
      marginTop: 10
    },
    [theme.breakpoints.down('sm')]: {
      float: 'none',
      paddingRight: 0
    }
  },
  dashboardFormSelect: {
    width: 300
  },
  dashboardButton: {
    position: 'relative',
    top: 3,
    width: 150
  },
  belowDashboardForm: {
    height: '100%',
    paddingTop: 60,
    [theme.breakpoints.down('md')]: {
      paddingTop: 0
    }
  },
  belowDashboardFormDashboard: {
    overflowY: 'auto',
    overflowX: 'hidden',
    paddingTop: 70
  },
  dashboardContentContainer: {
    marginBottom: 20
  },
  topDrilldownPages: {
    height: '100%',
    width: '100%',
    paddingTop: 10,
    [theme.breakpoints.down('sm')]: {
      height: 'initial'
    }
  },
  analyticsTableArea: {
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      height: 'initial'
    }
  },
  analyticsUsersTableArea: {
    height: 'calc(100% - 80px)',
    [theme.breakpoints.down('md')]: {
      height: 'calc(100% - 250px)'
    }
  },
  analyticsContentTableArea: {
    height: 'calc(100% - 160px)',
    [theme.breakpoints.down('md')]: {
      height: 'calc(100% - 250px)'
    }
  },
  analyticsScreensTableArea: {
    height: '100%',
    [theme.breakpoints.down('md')]: {
      height: 'calc(100% - 90px)'
    }
  },
  bigNumberHeading: {
    fontWeight: 600,
    marginBottom: 6,
    color: darkPeacock
  },
  smallNumberHeading: {
    textAlign: 'center',
    marginBottom: 3,
    color: gray,
    fontSize: '.70rem'
  },
  gridNine: {
    maxWidth: '11.11111%',
    flexBasis: '11.11111%',
    [theme.breakpoints.down('md')]: {
      maxWidth: '20%',
      flexBasis: '20%'
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: '33.33333%',
      flexBasis: '33.33333%'
    }
  },
  gridNineSmallPaddingRight: {
    padding: '4px 1px 4px 4px !important'
  },
  gridNineSmallPaddingRightLeft: {
    padding: '4px 1px !important'
  },
  gridNineSmallPaddingLeft: {
    padding: '4px 4px 4px 1px !important'
  },
  gridAnalyticsSpacer: {
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  bigNumberHeadingIcon: {
    position: 'relative',
    top: 3,
    marginRight: 5
  },
  bigNumberBox: {
    height: 75,
    textAlign: 'center',
    padding: '10px 30px',
    marginBottom: '5px'
  },
  smallNumberBox: {
    height: 40,
    textAlign: 'center',
    padding: '4.5px 5px',
    marginBottom: '20px'
  },
  bigNumberBoxSmall: {
    padding: '32px 30px'
  },
  smallNumberBoxSmall: {
    padding: '13px 10px'
  },
  bigNumber: {
    fontWeight: 300,
    color: white,
    fontSize: '2.25rem',
    marginTop: 4,
    [theme.breakpoints.down('sm')]: {
      marginTop: 11,
      fontSize: '1.5rem'
    }
  },
  bigNumberAvgSession: {
    fontSize: '2rem',
    marginTop: 3,
    [theme.breakpoints.down('sm')]: {
      marginTop: 11,
      fontSize: '1.5rem'
    }
  },
  smallNumber: {
    fontWeight: 300,
    color: white,
    fontSize: '1.2rem',
    lineHeight: 1.5,
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
      lineHeight: 1.65
    }
  },
  bigColorNumber: {
    fontWeight: 300,
    textAlign: 'center',
    marginTop: 30
  },
  bigNumberSmall: {
    fontSize: '1.5em'
  },
  smallNumberSmall: {
    fontSize: '1.2em'
  },
  listBox: {
    border: `1px solid ${lightGray}`,
    padding: 20,
    height: 253
  },
  doughnutBox: {
    position: 'relative',
    border: `1px solid ${lightGray}`,
    padding: 15,
    height: 440,
    [theme.breakpoints.down('sm')]: {
      height: '100%'
    }
  },
  deviceBox: {
    top: -4
  },
  doughnutContainer: {
    height: 350
  },
  doughnutViewContainer: {
    height: 300,
    margin: '25px 0'
  },
  orderedListHeading: {
    marginBottom: 3,
    color: darkPeacock,
    fontWeight: 600
  },
  deviceTypeHeading: {
    position: 'relative',
    top: -4
  },
  orderedList: {
    listStyle: 'none',
    margin: 0,
    padding: 0,
    color: darkPeacock
  },
  orderedListItem: {
    paddingBottom: 2
  },
  listLeft: {
    fontFamily: '"Gotham", "Helvetica", "Arial", sans-serif',
    display: 'inline-block',
    color: peacock,
    width: 'calc(90% - 5px)',
    padding: '0 5px 2px 0',
    float: 'left'
  },
  listRight: {
    display: 'inline-block',
    color: torquoise,
    width: '10%',
    padding: '0 0 2px 0',
    float: 'right',
    textAlign: 'right'
  },
  threeFourthGrid: {
    width: 'calc(75% + 12px)',
    margin: '15px -12px 0'
  },
  oneFourthGrid: {
    width: 'calc(25% + 12px)',
    margin: '15px -12px'
  },
  centerGrid: {
    textAlign: 'center'
  },
  leftGrid: {
    float: 'left'
  },
  rightGrid: {
    float: 'right'
  },
  columnGridPadding: {
    padding: '7px 14px !important'
  },
  gridSmallMarginTop: {
    marginTop: 0
  },
  gridMediumMarginTop: {
    marginTop: 8
  },
  gridMarginTop: {
    marginTop: 15
  },
  gridMarginBottom: {
    marginBottom: 15
  },
  topRepsContainer: {
    padding: '6px 12px 12px !important'
  },
  repsBox: {
    border: `1px solid ${lightGray}`,
    padding: '15px 15px 0'
  },
  topRepsHeading: {
    position: 'relative',
    zIndex: 100,
    fontWeight: 600,
    cursor: 'pointer',
    '&:hover': {
      color: darkEnergy
    }
  },
  topRepsIcon: {
    color: energy,
    position: 'relative',
    top: 6,
    marginLeft: 4,
    '&:hover': {
      color: darkEnergy
    }
  },
  sortableRow: {
    cursor: 'default'
  },
  smallRow: {
    height: 15,
    cursor: 'default'
  },
  dashboardGridButtonReset: {
    top: 2,
    right: 355
  },
  rightIcon: {
    zIndex: 100,
    float: 'right',
    position: 'relative',
    top: 40,
    right: 4,
    color: energy
  },
  rightIconUp: {
    top: 27
  },
  usersBox: {
    backgroundColor: gray,
    border: `1px solid ${gray}`
  },
  sessionsBox: {
    backgroundColor: peacock,
    border: `1px solid ${peacock}`
  },
  contactsBox: {
    backgroundColor: darkTorquoise,
    border: `1px solid ${darkTorquoise}`
  },
  showsBox: {
    backgroundColor: torquoise,
    border: `1px solid ${torquoise}`
  },
  viewsBox: {
    backgroundColor: teal,
    border: `1px solid ${teal}`
  },
  contentBox: {
    backgroundColor: dolphin,
    border: `1px solid ${dolphin}`
  },
  viewRateBox: {
    border: `1px solid ${dolphin}`
  },
  widgetHover: {
    borderColor: energy,
    opacity: .7,
    cursor: 'pointer'
  },
  viewRatePercentage: {
    position: 'absolute',
    top: 153,
    left: 0,
    width: '100%',
    margin: '0 auto',
    color: teal,
    fontSize: '4rem',
    fontWeight: 300,
    textAlign: 'center'
  },
  percentage: {
    fontSize: '2.5rem'
  },
  contentSizeContainer: {
    paddingTop: '0 !important'
  },
  contentSizeText: {
    lineHeight: 1.2
  },
  contentSizeNumber: {
    color: torquoise,
    fontSize: '1.85rem',
    lineHeight: 1.2
  },
  viewRateText: {
    position: 'relative',
    top: -10,
    margin: 10,
    fontSize: '1.05rem'
  }, 
  shareCount: {
    color: torquoise
  },
  viewCount: {
    color: teal
  },
  headerCell: {
    '&>div>div': {
      whiteSpace: 'normal !important'
    }
  },
  teamLibraryGroupContainer: {
    height: 'calc(100% - 70px)'
  },
  containerHeight: {
    height: '100%'
  },
  appProfileContainerHeight: {
    height: '50%'
  },
  teamLibraryGroupGridItem: {
    position: 'relative',
    maxHeight: '50%'
  },
  pageIcon: {
    float: 'left',
    position: 'relative',
    top: -13,
    fontSize: 60,
    marginRight: 10
  },
  appProfileGrid: {
    margin: '30px 20px',
    height: 'calc(100% - 30px)',
    overflow: 'auto'
  },
  cardContainer: {
    float: 'left'
  },
  card: {
    position: 'relative',
    width: 'calc(25% - 30px)',
    height: 360,
    float: 'left',
    margin: 15,
    border: 'none',
    borderRadius: 10,
    opacity: .85,
    cursor: 'pointer',
    overflow: 'visible',
    boxShadow: 'none',
    transition: 'opacity 3s cubic-bezier(.165, .84, .44, 1)',
    '&:hover': {
      opacity: 1
    }
  },
  appProfileCard: {
    width: '100%',
    height: 315,
    margin: 0,
    float: 'none'
  },
  cardContent: {
    position: 'relative'
  },
  cardMediaContainer: {
    position: 'relative',
    height: 250,
    borderBottom: `3px solid ${darkTorquoise}`
  },
  cardMedia: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    borderRadius: '10px 10px 0 0'
  },
  appProfileColorBackground: {
    width: '100%',
    height: 250,
    borderRadius: '10px 10px 0 0',
    color: lightGray
  },
  appProfileTitleArea: {
    height: 35,
    padding: '0px 15px 5px'
  },
  appProfileCardBrandArea: {
    padding: '5px 15px 0'
  },
  brandingSmallLogo: {
    position: 'absolute',
    top: '6%',
    left: '6%',
    width: 65,
    height: 'auto'
  },
  appProfileSmallLogo: {
    position: 'relative',
    top: 5,
    float: 'right',
    maxHeight: 22,
    height: 'auto',
    marginRight: 10
  },
  appProfileName: {
    fontSize: 16,
    fontWeight: 300,
    float: 'left',
    margin: '5px 8px'
  },
  brandingColorBlocks: {
    position: 'absolute',
    top: '6%',
    right: '6%',
    width: 35
  },
  brandingColorBlock: {
    float: 'left',
    width: 12.5,
    height: 12.5,
    margin: 2.5
  },
  appProfileColorBlocks: {
    float: 'right',
    position: 'relative',
    top: 6
  },
  appProfileColorBlock: {
    float: 'left',
    width: 15,
    height: 15,
    margin: 3
  },
  appProfileSubText: {
    color: peacock,
    fontSize: '.85rem'
  },
  appProfileCreatedDate: {
    color: gray
  },
  appProfileUpdatedDate: {
    color: torquoise,
    fontWeight: 300
  },
  appProfileButtonContainer: {
    textAlign: 'center'
  },
  appProfileButton: {

  },
  appProfileIcon: {
    position: 'absolute',
    right: 15,
    bottom: 30
  },
  downloadButton: {
    marginRight: 10
  },
  roomContainer: {
    display: 'flex',
    flexGrow: 1,
    flexFlow: 'column',
    position: 'relative',
    // height: '100%',
    minHeight: '100vh',
    paddingBottom: 70,
    backgroundColor: notBlack,
    backgroundPosition: 'left 30%',
    ['@media (max-height:665px)']: {
      height: 'initial'
    }
  },
  roomContainerOnlyOneItemIV: {
    // height: '100%'
  },
  oneIVContainer: {
    display: 'flex',
    width: '100%',
    height: '100%',
    flex: '1 1 auto',
    flexFlow: 'column',
    marginBottom: 20,
    [theme.breakpoints.down(1415)]: {
      padding: '0px 20px 20px'
    }
  },
  oneIV: {
    position: 'relative',
    display: 'flex',
    zIndex: 1,
    width: '100%',
    height: '100%',
    minHeight: 300,
    flexDirection: 'column', 
    flex: '1 1 auto'
  },
  fullscreenButton: {
    position: 'absolute',
    top: -40,
    right: 0,
    backgroundColor: white, 
    color: notBlack,
    width: 120,
    padding: '5px 0',
    textAlign: 'center',
    border: `1px solid ${white}`,
    borderRadius: 20,
    cursor: 'pointer',
    [theme.breakpoints.down('sm')]: {
      position: 'relative',
      top: 0,
      right: 0,
      float: 'right',
      marginBottom: 10
    }
  },
  roomMostRecentContainer: {
    display: 'flex',
    position: 'relative',
    overflow: 'hidden',
    margin: '0 auto',
    flex: '1 1 auto',
    height: '100%',
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'flex-start'
  },
  roomContainerWhite: {
    backgroundColor: white
  },
  showcase: {
    position: 'relative',
    minHeight: '100vh',
    paddingBottom: 70,
    backgroundColor: notBlack
  },
  showcaseContainer: {
    // maxWidth: 1200,
    margin: '0 auto',
    padding: 30
  },
  showcaseHeaderGrid: {
    padding: '30px !important',
    [theme.breakpoints.down(1120)]: {
      padding: '20px 30px !important'
    }
  },
  showcaseHeaderGridText: {
    [theme.breakpoints.down(820)]: {
      maxWidth: '100%',
      flexBasis: '100%'
    }
  },
  showcaseHeaderGridImage: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down(820)]: {
      display: 'none'
    }
  },
  showcaseMainheaderGrid: {
    marginBottom: 10,
    [theme.breakpoints.down(1120)]: {
      marginBottom: 0
    }
  },
  showcaseIcon: {
    width: '100%',
    position: 'relative',
    top: 20
  },
  showcaseHeaderImage: {
    width: '100%'
  },
  showcaseHeaderEnergy: {
    color: energy,
    fontWeight: 300,
    fontSize: 14
  },
  showcaseHeaderName: {
    color: white,
    fontWeight: 400,
    fontSize: 16
  },
  showcaseHeaderText: {
    color: lightGray,
    fontSize: 16
  },
  showcaseHeaderRightText: {
    color: lightGray,
    fontSize: 16,
    textAlign: 'right'
  },
  showcaseMainTextContainer: {
    padding: '50px 30px 30px !important'
  },
  showcaseH2: {
    color: energy,
    fontSize: 50,
    marginBottom: 15,
    [theme.breakpoints.down(1120)]: {
      fontSize: 45
    },
    [theme.breakpoints.down(1050)]: {
      fontSize: 40
    },
    [theme.breakpoints.down(950)]: {
      fontSize: 35
    },
    [theme.breakpoints.down(850)]: {
      fontSize: 30
    },
    [theme.breakpoints.down(820)]: {
      fontSize: 40
    }
  },
  showcaseH2Light: {
    color: gray
  },
  showcaseMainText: {
    color: gray,
    fontWeight: 300,
    fontSize: 20,
    [theme.breakpoints.down('xs')]: {
      fontSize: 18
    }
  },
  showcaseLink: {
    '&:hover': {
      color: energy
    }
  },
  roomHeader: {
    zIndex: 10,
    backgroundColor: 'transparent',
    color: white,
    boxShadow: 'none',
    // maxWidth: 1370,
    margin: '10px 0 0',
    padding: '0 10px',
    [theme.breakpoints.down(1415)]: {
      padding: '0 20px'
    }
  },
  roomHeaderToolbar: {
    padding: 0
  },
  roomHeaderContact: {
    color: white,
    fontWeight: 400,
    fontSize: 24,
    margin: '40px 0 10px',
    [theme.breakpoints.down('md')]: {
      marginTop: 25,
      fontSize: 20
    }, 
    [theme.breakpoints.down('sm')]: {
      fontSize: 18
    },
  },
  roomHeaderWelcome: {
    fontWeight: 200
  },
  expiredHeader: {
    fontWeight: 200,
    fontSize: 28
  },
  roomHeaderUser: {
    margin: '10px 0'
  },
  roomHeaderName: {
    color: white,
    fontWeight: 300,
    lineHeight: '1.3em !important',
    fontSize: '1rem',
    [theme.breakpoints.down('xs')]: {
      fontSize: '.9em !important'
    },
  },
  roomHeaderText: {
    lineHeight: '1.1em',
    color: gray
  },
  roomTextEnergy: {
    color: energy
  },
  roomText: {
    color: notBlack,
    fontWeight: 300,
    fontSize: 16,
    lineHeight: '1.3em',
    marginBottom: 5,
    maxWidth: 800,
    [theme.breakpoints.down('xs')]: {
      marginTop: 5,
      fontSize: 14
    }
  },
  roomHeaderContainer: {
    display: 'inline-block',
    padding: '10px 0',
    '&>img': {
      position: 'absolute',
      top: -2,
      padding: '17px 0 0 15px',
      maxHeight: 60
    },
    [theme.breakpoints.down('xs')]: {
      '&:first-of-type': {
        padding: '7px 0'
      },
      '&>img': {
        top: -1,
        maxHeight: 50
      }
    }
  },
  roomHeaderContainerLogo: {
    width: 50,
    // [theme.breakpoints.down('xs')]: {
    //   top: -23
    // }
  },
  // showcaseHeaderContainerLogo: {
  //   top: -75
  // },
  roomPreviousSection: {
    position: 'relative',
    backgroundColor: white
  },
  roomFirstPreviousSection: {
    '&:first-of-type': {
      paddingTop: 35
    },
    '&:last-of-type': {
      paddingBottom: 25
    }
  },
  roomMostRecentSection: {
    backgroundColor: 'transparent'
  },
  showcaseMostRecentSection: {
    backgroundColor: notBlack,
    padding: '0 30px',
    [theme.breakpoints.down(1415)]: {
      padding: 0
    },
  },
  showcaseMostRecentSectionLight: {
    backgroundColor: white
  },
  roomSectionContainer: {
    margin: '0 auto',
    // maxWidth: 1370,
    padding: '12px 12px 0',
    [theme.breakpoints.down(1415)]: {
      padding: '12px 35px 0'
    },
    [theme.breakpoints.down('xs')]: {
      padding: '12px 20px 0'
    }
  },
  roomMostRecentSectionContainer: {
    padding: '20px 12px 50px',
    margin: '0 auto',
    // maxWidth: 1370,
    [theme.breakpoints.down(1415)]: {
      padding: '20px 35px 40px'
    },
    [theme.breakpoints.down('xs')]: {
      padding: '5px 20px 40px'
    }
  },
  roomSectionHeader: {
    fontSize: 18,
    paddingRight: 10,
    [theme.breakpoints.down('md')]: {
      fontSize: 16
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 14
    }
  },
  roomSectionSession: {
    padding: '12px 10px 5px',
    [theme.breakpoints.up(1448)]: {
      padding: '12px 0 5px'
    },
  },
  roomMostRecentSectionSession: {
    padding: '12px 10px 15px',
    [theme.breakpoints.down(1415)]: {
      padding: '12px 0 15px',
    }
  },
  roomMostRecentText: {
    color: lightGray
  },
  roomInitialsContainer: {
    position: 'relative',
    top: -2,
    fontSize: 11,
    display: 'inline-block',
    marginRight: 5,
    width: 21,
    height: 21,
    backgroundColor: notBlack,
    textAlign: 'center',
    borderRadius: '50%',
    webkitBorderRadius: '50%',
    mozBorderRadius: '50%'
  },
  roomMostRecentInitialsContainer: {
    backgroundColor: dolphin
  },
  roomInitials: {
    position: 'relative',
    top: 1,
    color: white,
    fontSize: 11
  },
  roomItemsContainer: {
    width: '100%',
    marginTop: 5,
    // whiteSpace: 'nowrap',
    // height: 254,
    [theme.breakpoints.down('xs')]: {
      height: 'initial !important'
    }
  },
  roomMostRecentItemsContainer: {
    // height: 295
  },
  roomItem: {
    position: 'relative',
    display: 'inline-block',
    width: 180,
    margin: '0 25px 10px 0',
    '&:last-of-type': {
      margin: '0 0 10px 0'
    },
    [theme.breakpoints.down('xs')]: {
      top: '0px !important',
      width: '100% !important',
      height: '105px !important',
      display: 'block !important',
      margin: '0px !important',
      borderTop: '1px solid !important',
      borderColor: `${lightGray} !important`,
      '&:first-of-type': {
        borderTop: '0px !important'
      }
    }
  },
  showcaseItemContainer: {
    width: '95%',
    margin: '0 auto',
    [theme.breakpoints.down(1120)]: {
      width: '93%',
      marginBottom: 20,
      overflowX: 'scroll',
      whiteSpace: 'nowrap'
    }
  },
  showcaseItem: {
    display: 'inline-block',
    width: '33.33%',
    [theme.breakpoints.down('sm')]: {
      width: 'initial',
      margin: '0 20px'
    }
  },
  roomMostRecentItem: {
    width: 200,
    margin: '0 35px 15px 0',
    '&:last-of-type': {
      margin: '0 0 15px 0'
    },
    [theme.breakpoints.down('xs')]: {
      borderColor: `${darkDolphin} !important`
    }
  },
  cardShowcaseItem: {
    position: 'relative',
    top: 5,
    width: 320
  },
  cardShowcase: {
    width: '320px !important'
  },
  showcaseTitleClass: {
    width: '290px !important'
  },
  roomCardTitle: {
    width: 166,
    margin: '0 auto',
    cursor: 'pointer',
    '&>div': {
      display: 'inline-block'
    },
    [theme.breakpoints.down('xs')]: {
      position: 'relative !important',
      top: '38px !important',
      width: 'calc(100% - 80px) !important',
      float: 'left !important',
      marginLeft: '10px !important'
    }
  },
  roomMostRecentCardTitle: {
    width: 176
  },
  roomItemName: {
    display: 'inline-block',
    position: 'relative',
    top: -4,
    paddingLeft: 4,
    width: '130px !important',
    color: notBlack,
    fontSize: 13,
    fontFamily: '"Gotham", "Helvetica", "Arial", sans-serif',
    fontWeight: 300,
    lineHeight: '1.3em',
    [theme.breakpoints.down('xs')]: {
      width: '180px !important',
      top: '-4px !important'
    },
    [theme.breakpoints.down(360)]: {
      width: '140px !important'
    }
  },
  roomMostRecentItemName: {
    top: -4,
    color: white,
    width: '140px !important'
  },
  showcaseNameClass: {
    top: -2,
    fontSize: '18px !important',
    width: '250px !important'
  },
  roomCardContainer: {
    [theme.breakpoints.down('xs')]: {
      position: 'relative',
      width: '100%',
      height: '100%'
    }
  },
  roomCard: {
    float: 'initial',
    width: 180,
    height: 195,
    margin: '10px 0',
    opacity: 1,
    // border: `1px solid ${lightGray}`,
    borderRadius: '30px !important',
    [theme.breakpoints.down('xs')]: {
      float: 'left !important',
      width: '70px !important',
      height: '82px !important',
      borderRadius: '15px !important'
    }
  },
  roomMostRecentCard: {
    width: 200,
    height: 220,
    border: 'none'
  },
  roomMostRecentCardLight: {
    // border: `1px solid ${lightGray}`
  },
  roomCardNoBorder: {
    border: 'none'
  },
  showcaseCard: {
    width: 290,
    height: 320,
    // border: `1px solid ${darkGrayBorder}`,
    borderRadius: '30px !important',
    margin: '20px auto',
    [theme.breakpoints.down(1120)]: {
      width: 260,
      height: 290
    }
  },
  showcaseCardMedia: {
    borderRadius: 27
  },
  roomCardMedia: {
    borderRadius: 27,
    [theme.breakpoints.down('xs')]: {
      borderRadius: '10px !important'
    }
  },
  roomButton: {
    position: 'absolute',
    top: -21,
    left: '50%',
    webkitTransform: 'translateX(-50%)',
    transform: 'translateX(-50%)',
    fontSize: 16,
    padding: '4px 16px 4px 30px',
    [theme.breakpoints.down('xs')]: {
      padding: '4px 10px 4px 26px',
      fontSize: 14
    }
  },
  roomMostRecentButtonContainer: {
    textAlign: 'center'
  },
  roomMostRecentButton: {
    top: 63,
    fontSize: 18,
    borderRadius: 30,
    paddingLeft: 32,
    [theme.breakpoints.down('xs')]: {
      top: 73,
      fontSize: 16,
      width: 220,
      padding: '2px 8px 2px 24px'
    }
  },
  roomDownloadButton: {
    cursor: 'pointer',
    position: 'absolute',
    bottom: 7,
    right: 15,
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    }
  },
  roomDownloadButtonMobile: {
    cursor: 'pointer',
    position: 'absolute',
    bottom: 5,
    right: 35,
    [theme.breakpoints.up('sm')]: {
      display: 'none'
    }
  },
  showcaseDownloadButton: {
    position: 'absolute',
    bottom: 5,
    right: 8,
    marginRight: 10
  },
  roomDownloadIcon: {
    color: energy,
    width: 25,
    height: 25
  },
  
  roomDownloadIconMobile: {
    width: 30,
    height: 30
  },
  roomModalDownloadButton: {
    position: 'relative',
    top: -1,
    paddingLeft: 10
  },
  roomPoweredByBar: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    height: 70,
    backgroundColor: lightGray
  },
  roomPoweredByBarOnlyOneItemIV: {
    position: 'absolute',
    bottom: 0
  },
  roomPoweredByText: {
    margin: '15px 30px 15px',
    textAlign: 'left',
    color: notBlack,
    fontWeight: 300
  },
  roomPoweredByTextSpan: {
    position: 'relative',
    top: -10,
    '&>a': {
      textDecoration: 'underline !important'
    }
  },
  roomPoweredByLogo: {
    width: 150,
    marginLeft: 3
  },
  previewLinkContainer: {
    marginTop: '15%',
    [theme.breakpoints.down('sm')]: {
      height: '50%'
    }
  },
  previewLink: {
    color: energy
  },
  replaceLoading: {
    display: 'inline-block',
    position: 'relative',
    top: 26,
    width: 50,
    height: 'auto'
  },
  fullModalImageContainer: {
    width: 'calc(100% - 20%)',
    height: 'calc(100% - 20%)',
    maxWidth: 900,
    maxHeight: '100%',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      height: 'auto'
    }
  },
  fullModalImage: {
    maxHeight: '100%',
    maxWidth: '100%',
    height: 'auto',
    width: 'auto',
    cursor: 'pointer'
  },
  explainerLink: {
    color: energy,
    cursor: 'pointer'
  },
  openModalLink: {
    color: energy,
    cursor: 'pointer'
  },
  generatedLinks: {
    height: '100%',
    overflowY: 'auto',
    fontWeight: 300,
    color: peacock
  },
  roomContactInfoContainer: {
    margin: '5px 0 0',
    padding: '0 10px 10px',
    // maxWidth: 1370,
    [theme.breakpoints.down(1415)]: {
      padding: '0 20px 0'
    },
    [theme.breakpoints.down('sm')]: {
      padding: '0 20px 0'
    }
  },
  roomContactIconButton: {
    padding: '2px 8px',
    fontSize: 12,
    color: energy,
    borderColor: energy
  },
  roomContactIcon: {
    position: 'relative',
    top: -1,
    width: 15,
    height: 15,
    marginRight: 5
  },
  justVerticalMiddle: {
    position: 'relative',
    top: '50%',
    transform: 'translateY(-50%)'
  },
  verticallyCenter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    top: '50%',
    transform: 'translateY(-50%)',
    textAlign: 'center',
    margin: '0 auto'
  },
  spaceWelcomeArea: {
    // maxWidth: 1370,
    margin: 0,
    padding: '0 10px 10px',
    [theme.breakpoints.down(1415)]: {
      padding: '0 20px 10px'
    },
    [theme.breakpoints.down('sm')]: {
      padding: '0 20px 10px'
    }
  },
  shareForm: {
    height: '375px',
    maxHeight: '67%'
  },
  sharedContentContainer: {
    marginTop: 10,
    height: 'calc(100% - 410px)',
    overflowY: 'scroll'
  },
  sharedLink: {
    margin: '5px 0 18px',
    fontSize: '.95rem',
    paddingBottom: 7
  },
  sharedHeading: {
    fontSize: '.80rem',
    fontWeight: 600
  },
  sharedHeadingInteractiveVideoDetails: {
    fontSize: '.90rem',
    fontWeight: 600,
    marginBottom: 5
  },
  shareContact: {
    color: verbSecondary,
    marginBottom: 10,
    fontWeight: 600
  },
  previewSpaceLink: {
    cursor: 'pointer',
    background: energy,
    borderRadius: 16,
    color: white,
    fontSize: '.8rem',
    marginLeft: 8,
    padding: '4px 10px 6px',
    lineHeight: 0,
    '&:hover': {
      backgroundColor: darkEnergy
    },
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      top: 0,
      lineHeight: 'initial',
      width: 100,
      margin: '7px 0',
      textAlign: 'center'
    }
  },
  viewInteractiveVideoAnalytics: {
    fontSize: '.95rem',
    padding: '8px 16px 10px',
    borderRadius: 20
  },
  copiedLink: {
    marginLeft: 10,
    color: energy
  },
  gateText: {
    color: peacock,
    fontSize: 22,
    fontWeight: 300,
    textAlign: 'center',
    paddingBottom: 5
  },
  brandFormContainer: {
    position: 'relative'
  },
  teamBrandForm: {
    overflowX: 'visible',
    overflowY: 'visible'
  },
  onboardingFormNoMaxHeight: {
    maxHeight: 'initial'
  },
  onboardingStepNumber: {
    position: 'relative',
    top: -2,
    display: 'inline-block',
    marginRight: 15,
    borderRadius: '50%',
    width: 46,
    height: 46,
    backgroundColor: verbPurple,
    padding: 6,
    fontFamily: '"Open Sans"',
    fontSize: '1.8rem',
    fontWeight: 600,
    color: white
  },
  onboardingCheckboxNumbers: {
    fontFamily: '"Open Sans" !important',
    fontSize: '1rem'
  },
  onboardingBackgroundContainer: {
    backgroundPosition: 'right',
    backgroundImage: 'url(/images/gray_slash_back_L.png)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '75% 100%'
  },
  onboardingBackgroundReverseContainer: {
    backgroundImage: 'url(/images/gray_slash_back_r.png)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '75% 100%'
  },
  onboardingBrandBackgroundContainer: {
    backgroundSize: '78% 100%'
  },
  onboardingSteps: {
    fontSize: '1.05rem',
    lineHeight: '1.55rem'
  },
  onboardingStepsBold: {
    fontWeight: 600
  },
  onboardingGrid: {
    margin: '30px 0'
  },
  onboardingGridLeftTextArea: {
    paddingLeft: '50px !important',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '16px !important'
    }
  },
  onboardingH1: {
    fontFamily: 'Gotham',
    fontWeight: 600,
    fontSize: '2.2rem',
    color: peacock,
    textAlign: 'center',
    textTransform: 'uppercase'
  },
  onboardingImageContainer: {
    margin: '0 60px'
  },
  onboardingImageOrganizationContainer: {
    margin: '50px 60px'
  },
  onboardingImage: {
    width: '100%',
    height: 'auto'
  },
  onboardingH2: {
    fontFamily: 'Gotham',
    fontWeight: 600,
    fontSize: '1.7rem',
    textAlign: 'left'
  },
  onboardingH2Bold: {
    color: verbPurple,
    fontWeight: 600
  },
  onboardingSubtitle: {
    fontFamily: 'Gotham',
    fontWeight: 300,
    fontSize: '.9rem',
    color: peacock,
    textAlign: 'left'
  },
  onboardingSubtitleContentGroups: {
    margin: '15px 0',
    fontSize: '.9rem'
  },
  onboardingContentGroupsExtraText: {
    fontSize: '.9rem',
    position: 'relative',
    top: 8,
    left: 4
  },
  onboardingContentGroupsCreateContainer: {
    marginTop: 5
  },
  onboardingBody: {
    fontWeight: 300,
    fontSize: '.9rem',
    marginBottom: 20
  },
  onboardingTextfield: {
    width: 450,
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    }
  },
  onboardingContentGroupsCheckboxes: {
    width: 440,
    margin: '0 auto'
  },
  onboardingCheckbox: {
    margin: '10px 13px'
  },
  onboardingButton: {
    backgroundColor: verbPurple,
    color: white,
    float: 'initial',
    margin: '30px 0 10px',
    '&:hover': {
      backgroundColor: verbDarkPurple
    }
  },
  onboardingBrandButton: {
    float: 'initial',
    margin: '0px 0 10px'
  },
  onboardingSkipButton: {
    float: 'initial',
    marginRight: 10,
    border: `1px solid ${verbPurple}`,
    color: verbPurple,
    '&:hover': {
      backgroundColor: verbDarkPurple,
      color: white
    }
  },
  onboardingBrandSkipButton: {
    margin: 0
  },
  onboardingContentSkipButton: {
    marginTop: 30
  },
  onboardingCompletedButton: {
    float: 'initial',
    maxWidth: 215,
    margin: '20px 10px 0'
  },
  onboardingFilestackButton: {
    display: 'block',
    textAlign: 'left',
    marginLeft: 25
  },
  onboardingExtraText: {
    margin: '0 auto'
  },
  onboardingAdminCheckbox: {
    marginLeft: 10
  },
  onboardingCheckedDone: {
    color: `${gray} !important`
  },
  onboardingButtonContainer: {
    textAlign: 'center',
    margin: '20px auto 0',
    [theme.breakpoints.down('sm')]: {
      marginBottom: 30
    }
  },
  onboardingBrandButtonContainer: {
    textAlign: 'center',
    margin: '10px auto 0'
  },
  onboardingAdd: {
    background: 'none',
    float: 'left'
  },
  addButtonOnboarding: {
    backgroundColor: energy,
    color: white
  },
  onboardingContentFormGrid: {
    minHeight: 435
  },
  onboardingUserError: {
    width: 450,
    margin: '8px auto'
  },
  onboardingGridBrandGraphic: {
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  topMessageContainer: {
    width: 450,
    maxWidth: '95%',
    margin: '0 auto 15px',
    padding: 15,
    backgroundColor: azureMist,
    textAlign: 'center'
  },
  topMessageLeftContainer: {
    maxWidth: 300,
    margin: '10px 10px 10px 0',
    padding: 10
  },
  topMessage: {
    fontSize: '1.05rem'
  },
  trialNotification: {
    cursor: 'pointer',
    position: 'absolute',
    top: 86,
    right: 0,
    fontSize: 13,
    fontWeight: 300,
    backgroundColor: energy,
    color: white,
    padding: '3px 15px',
    borderBottomLeftRadius: 18,
    [theme.breakpoints.down('sm')]: {
      top: 0
    },
    [theme.breakpoints.down('xs')]: {
      backgroundColor: 'transparent'
    }
  },
  trialNotificationActivity: {
    top: 76,
    [theme.breakpoints.down('sm')]: {
      top: 0
    }
  },
  trialNotificationStrong: {
    fontWeight: 600
  },
  billingTitle: {
    fontSize: '1.7rem',
    lineHeight: 1.5,
    marginTop: 20
  },
  billingSubtitle: {
    position: 'relative',
    top: -7,
    display: 'inline',
    fontSize: 20,
    fontWeight: 600,
    lineHeight: '2.2em',
    [theme.breakpoints.down('md')]: {
      fontSize: 18
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 16
    }
  },
  billingSubtitleSmall: {
    fontWeight: 200,
    fontSize: 16
  },
  billingRow: {
    paddingBottom: 15
  },
  billingButton: {
    color: energy,
    cursor: 'pointer'
  },
  billingGrid: {
    width: '100%',
    margin: '20px 0 0 0'
  },
  billingGridHeader: {
    backgroundColor: lightGray,
    [theme.breakpoints.down('xs')]: {
      padding: '20px !important'
    }
  },
  billingGridLogo: {
    width: 50,
    maxWidth: 50
  },
  billingLogo: {
    display: 'inline',
    position: 'relative',
    top: 5,
    width: 35,
    marginRight: 10
  },
  billingGridBody: {
    backgroundColor: evenLighterGray,
    position: 'relative'
  },
  billingGridBodyRight: {
    borderLeft: `1px solid ${beforeLightGray}`
  },
  billingPrice: {
    marginTop: 20
  },
  billingSmallText: {
    fontSize: 10
  },
  billingCancelContainer: {
    position: 'absolute',
    bottom: 25,
    right: 30,
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      position: 'relative',
      bottom: 'initial',
      right: 'initial'
    }
  },
  billingCancelText: {
    fontSize: 12,
    cursor: 'pointer',
    float: 'right',
    [theme.breakpoints.down('sm')]: {
      float: 'none',
      textAlign: 'right',
      marginTop: 10
    }
  },
  radioContainer: {
    marginTop: 15
  },
  radioLabel: {
    marginLeft: 0,
    [theme.breakpoints.down('xs')]: {
      display: 'block',
      width: '100%',
    }
  },
  billingRadioLabelText: {
    marginLeft: 10,
    color: `${verbDarkHeading} !important`
  },
  billingRadioRoot: { 
    '&>span>svg':  {
      color: torquoise,
      width: 22,
      height: 22
    },
    '&:nth-of-type(2)': {
      marginLeft: 20,
      paddingLeft: 20,
      borderLeft: `1px solid ${gray}`,
      [theme.breakpoints.down('sm')]: {
        marginLeft: 10,
        paddingLeft: 10
      },
      [theme.breakpoints.down('xs')]: {
        border: 'none',
        margin: '20px 0 0 0',
        paddingLeft: 0
      }
    }
  },
  billingAddNewCard: {
    position: 'relative',
    top: 25,
    [theme.breakpoints.down('md')]: {
      top: 0
    }
  },
  billingSettingsArea: {
    height: 200,
    [theme.breakpoints.down('md')]: {
      height: '100%'
    }
  },
  billingPaymentCard: {
    marginLeft: 20,
    position: 'relative',
    top: 35,
    [theme.breakpoints.down('md')]: {
      top: 0
    }
  },
  billingHints: {
    position: 'absolute',
    bottom: 10,
    left: 30, 
    width: '100%',
    [theme.breakpoints.down('md')]: {
      position: 'relative',
      bottom: 0,
      left: 0,
      marginTop: 20
    }
  },
  onboardingBrandText: {
    width: 'calc(89% - 15px)',
    margin: '0 auto'
  },
  tourContainer: {
    position: 'absolute',
    overflow: 'scroll',
    width: '100vw',
    height: '100vh',
    backgroundColor: 'rgba(1,22,39,.75)',
    zIndex: 1000
  },
  tourModal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 1000,
    height: 'auto',
    maxHeight: '100%',
    [theme.breakpoints.down('md')]: {
      width: '100%'
    }
  },
  tourCard: {
    width: '100%',
    height: 'auto',
    margin: '0 auto'
  },
  tourButtonContainer: {
    position: 'relative',
    bottom: 75,
    width: 270,
    margin: '0 auto',
    [theme.breakpoints.down('sm')]: {
      top: 30
    }
  },
  videoTourButtonContainer: {
    position: 'absolute',
    bottom: 40,
    transform: 'translate(-50%, -50%)',
    left: '50%',
    width: 275,
    [theme.breakpoints.down('sm')]: {
      bottom: 100
    },
    [theme.breakpoints.down('xs')]: {
      bottom: 140
    }
  },
  tourButton: {
    float: 'left',
    width: 130,
    '&:first-of-type': {
      marginRight: 10
    }
  },
  tourPreviousButton: {
    backgroundColor: torquoise,
    color: white,
    '&:hover': {
      backgroundColor: darkTorquoise
    }
  },
  tourButtonDisabled: {
    backgroundColor: `${darkGray} !important`,
    color: `${white} !important`
  },
  needBilling: {
    color: energy,
    fontWeight: 300,
    lineHeight: .7,
    paddingBottom: 10
  },
  gridRightBorder: {
    borderRight: `1px solid ${peacock}`,
    marginTop: 12,
    padding: '0 20px 12px 12px !important',
    [theme.breakpoints.down('sm')]: {
      marginTop: 5,
      padding: '0 !important',
    }
  },
  gridPaddingLeft: {
    marginTop: 12,
    padding: '0 12px 12px 25px !important',
    [theme.breakpoints.down('sm')]: {
      padding: '0 6px 12px 10px !important',
    }
  },
  libraryListHeader: {
    position: 'absolute',
    width: 'calc(100% - 250px)',
    flexWrap: 'nowrap',
    [theme.breakpoints.down('sm')]: {
      position: 'relative',
      width: '100%'
    }
  },
  libraryListHeaderItem: {
    maxWidth: 'calc(100% - 175px)',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
      padding: '0px 4px !important'
    }
  },
  selectedTeamHeader: {
    position: 'absolute',
    zIndex: 100,
    marginBottom: 3,
    color: navy,
    fontSize: 21,
    fontWeight: 300,
    lineHeight: 2.3,
    [theme.breakpoints.down('md')]: {
      fontSize: 17
    },
    [theme.breakpoints.down('sm')]: {
      position: 'relative',
      width: 'calc(100% - 50px)',
      fontSize: 16
    }
  },
  selectedTeamHeaderLibrary: {
    position: 'relative'
  },
  plusButtonGrid: {
    zIndex: 10,
    position: 'relative',
    top: 4
  },
  alertContainer: {
    position: 'fixed',
    width: '100%',
    height: 60, 
    backgroundColor: energy,
    textAlign: 'center',
    padding: 10
  },
  alertIcon: {
    position: 'relative',
    top: 3
  },
  alertTeamIcon: {
    color: energy
  },
  alertText: {
    color: white,
    fontSize: '.95rem'
  },
  alertsHeader: {
    top: 60 
  },
  alertsRoot: {
    padding: '168px 30px 0'
  },
  alertsDashboardForm: {
    top: 195
  },
  alertsBelowDashboardForm: {
    paddingTop: 110
  },
  usersLeftList: {
    '&>li:nth-of-type(1)': {
      borderWidth: 0
    },
    '&>li:nth-of-type(4)': {
      borderWidth: 0
    },
    '&>li:nth-of-type(3)>div': {
      borderWidth: 0
    },
  },
  masterAdminContainer: {
    padding: 30
  },
  masterAdminHeading: {
    fontSize: '2.2rem',
    marginBottom: 20
  },
  masterAdminSubHeading: {
    fontSize: '1.5rem',
    margin: '20px 0 10px 0'
  },
  masterAdminSubHeading2: {
    fontSize: '1.5rem',
    margin: '35px 0 0 0'
  },
  dropdownMasterAdmin: {
    marginBottom: 15,
    width: 400
  },
  dropdownContactSearch: {
    width: '100%',
    width: 300
  },
  masterCompany: {
    display: 'block'
  },
  masterCompanyName: {
    fontSize: 16
  },
  masterCompanyCreatedAt: {
    fontSize: 14,
    color: torquoise
  },
  secondDropdownMasterAdmin: {
    marginLeft: 15
  },
  masterAdminLogout: {
    cursor: 'pointer',
    color: energy,
    marginLeft: 15,
    fontSize: 16
  },
  masterAdminTextfield: {
    maxWidth: 450
  },
  masterAdminButton: {
    width: 150
  },
  teamRating: {
    fontSize: 25,
    paddingBottom: 18
  },
  reviewsGrid: {
    height: 'calc(100% - 50px)',
    width: 'calc(100% + 34px)',
    padding: '5px 15px 12px !important'
  },
  reviewsGridItem: {
    height: 'calc(100% - 10px)',
    overflowY: 'scroll'
  },
  review: {
    marginBottom: 17
  },
  averageReviewNoRatings: {
    color: torquoise,
    paddingLeft: 7,
    fontSize: 20
  },
  averageReview: {
    color: torquoise,
    paddingLeft: 7
  },
  starRatingAverage: {
    position: 'relative',
    top: 3,
    color: torquoise,
    paddingLeft: 5
  },
  starRating: {
    position: 'relative',
    top: 3,
    color: energy
  },
  reviewDate: {
    fontSize: 13
  },
  reviewReview: {
    margin: '5px 0 0 10px',
    fontStyle: 'italic'
  },
  centerGridElement: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    margin: '0 auto'
  },
  centerGridElementPreviewTitle: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      margin: '25px auto 0px'
    }
  },
  previousNextThumb: {
    height: 'auto',
    width: 'auto',
    maxHeight: 50,
    maxWidth: 50
  },
  mobilePreviousNextThumb: {
    maxHeight: 40,
    maxWidth: 40
  },
  textFieldNoMarginTop: {
    marginTop: 0
  },
  textFieldNoMarginBottom: {
    marginBottom: 0
  },
  switchMarginTop: {
    marginTop: 10
  },
  aspectRatioBox: {
    // position: 'relative',
    // height: 0,
    // overflow: 'hidden',
    paddingTop: '56.25%'
  },
  aspectRatioBoxInside: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%'
  },
  flexboxCentering: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  importantInfoText: {
    color: torquoise,
    paddingBottom: 5
  },
  importantConfirmResetPassword: {
    marginTop: 20
  },
  editHeading: {
    marginBottom: 8,
    color: peacock,
    fontSize: 18
  },
  editSecondHeading: {
    marginTop: 20
  },
  bulkWarningHeading: {
    marginTop: 20,
    color: torquoise,
    fontSize: '1.15rem'
  },
  truncateText: {
    width: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    paddingRight: 5
  },
  truncateTextExtra: {
    paddingRight: 10,
    paddingTop: 3
  },
  listItemCount: {
    backgroundColor: white,
    padding: '0 10px',
    borderRadius: 20,
    float: 'right',
    fontSize: 17,
    color: energy,
    [theme.breakpoints.down(1150)]: {
      fontSize: 15
    }
  },
  reshareExpansionPanel: {
    zIndex: 100,
    position: 'fixed',
    top: 0, left: '50%',
    margin: '0 0 0 -150px',
    border: 'none !important',
    width: '100%',
    maxWidth: 300
  },
  reshareExpansionPanelExpanded: {
    margin: '0 0 0 -150px !important'
  },
  reshareExpansionPanelSummary: {
    margin: '10px 0 15px !important'
  },
  reshareExpansionPanelDetails: { 
    maxHeight: 'calc(100vh - 300px)',
    overflow: 'auto'
  },
  reshareCartHeaderText: {
    color: white,
    width: '100%',
    fontWeight: 300,
    fontSize: '1.2rem', 
    lineHeight: 1.2,
    marginTop: 10
  },
  reshareListItemCount: {
    fontSize: 16,
    padding: '1px 10px'
  },
  reshareCartContainer: { 
    height: 40,
    paddingBottom: 7,
    marginBottom: 12 
  },
  reshareCartItem: { 
    display: 'inline',
    float: 'left',
    marginRight: 10,
    width: 30 
  },
  roomShareIcon: {
    color: white,
    width: 18,
    height: 18
  },
  roomShareButton: {
    position: 'absolute',
    cursor: 'pointer',
    left: 15,
    bottom: 10,
    padding: 2,
    backgroundColor: energy,
    borderRadius: 5,
    [theme.breakpoints.down('xs')]: {
      left: 'initial',
      bottom: 9,
      right: 0
    }
  },
  selectedRoomShareButton: {
    border: `1px solid ${energy}`
  },
  reshareExpandIcon: {
    top: 'initial',
    bottom: -25,
    color: white
  },
  reshareTextField: {
    float: 'left',
    width: 185,
    marginTop: 5,
    paddingBottom: 25
  },
  reshareFormControl: {
    display: 'inline',
    margin: '5px 0 0 0'
  },
  reshareInputLabel: {
    color: `${white} !important`,
    fontSize: '1.1rem'
  },
  reshareInput: {
    color: `${white} !important`,
    fontSize: '.9rem'
  },
  reshareInputUnderline: {
    '&:before': {
      borderBottom: `1px solid ${white} !important`
    },
    '&:after': {
      borderBottom: `2px solid ${white} !important`
    }
  },
  reshareError: {
    display: 'none'
  },
  reshareInfoText: {
    position: 'absolute',
    top: 57,
    width: 130,
    float: 'left',
    color: '#fff',
    fontSize: 12,
    fontWeight: 200
  },
  mainReshareButtonContainer: { 
    position: 'absolute',
    top: 0,
    right: 0,
    margin: 20 
  },
  mainReshareButton: {
    zIndex: 100,
    borderRadius: 15,
    padding: 3
  },
  reshareButton: {
    display: 'inline',
    position: 'relative',
    bottom: -64,
    padding: 6,
    lineHeight: 1.15,
    float: 'right',
    width: 75,
    backgroundColor: white,
    '&:hover': {
      backgroundColor: white
    }
  },
  reshareButtonDisabled: {
    backgroundColor: '#0000003f !important'
  },
  reshareItemImage: { 
    height: 30,
    width: 'auto',
    maxWidth: 30,
    marginRight: 7
  },
  reshareItemText: { 
    display: 'inline',
    float: 'left',
    color: white,
    width: 185 
  },
  reshareRemoveButton: { 
    display: 'inline',
    float: 'right',
    color: white,
    width: 20,
    height: 20 
  },
  resharePreviewWindow: {
    position: 'relative',
    bottom: 2,
    '&:hover': {
      backgroundColor: notBlack
    }
  },
  resharePreviewWindowMobile: {
    position: 'relative',
    bottom: 1,
    marginLeft: 10
  },
  blurBackgroundWrapper: {
    maxWidth: '100%',
    marginBottom: 0,
    backgroundImage: 'url(/images/blur_background.jpg)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center center'
  },
  loginSizeOfScreen: {
    height: 'calc(100vh - 76px)',
    minHeight: 700
  },
  showcaseBackgroundImageContainer: {
    width: '100%',
    minHeight: 450,
    [theme.breakpoints.down('xs')]: {
      minHeight: 550
    }
  },
  showcaseHeaderContainer: {
    position: 'sticky',
    top: 0,
    left: 0,
    height: 75,
    backgroundColor: 'rgba(1, 22, 39, .6)',
    padding: '10px 20px'
  },
  showcaseHeaderContainerLight: {
    backgroundColor: 'rgba(255, 255, 255, .8)'
  },
  showcaseHeader: {
    width: '100%',
    height: '100%'
  },
  showcaseHeaderLogo: {
    height: 50,
    width: 'auto',
    [theme.breakpoints.down('xs')]: {
      height: 35
    }
  },
  showcaseHeaderContactInfo: {
    float: 'right'
  },
  showcaseTitleAreaContainer: {
    padding: 30,
    marginBottom: 20,
    [theme.breakpoints.down('sm')]: {
      padding: 20
    }
  },
  showcaseTitleArea: {
    maxWidth: 600,
    margin: 20,
    padding: '15px 30px 25px',
    backgroundColor: 'rgba(1, 22, 39, .6)',
    borderRadius: 5,
    overflow: 'hidden',
    [theme.breakpoints.down(1415)]: {
      margin: 20
    }
  },
  showcaseTitle: {
    color: lightGray,
    fontWeight: 300,
    fontSize: 45,
    lineHeight: '50px',
    paddingBottom: 10,
    [theme.breakpoints.down('sm')]: {
      fontSize: 35,
      lineHeight: '40px',
      maxWidth: '100%'
    }
  },
  showcaseSubtitle: {
    display: 'inline-block',
    backgroundColor: energy,
    color: white,
    fontWeight: 300,
    fontSize: 30,
    lineHeight: '30px',
    padding: '5px 10px',
    marginBottom: 24,
    [theme.breakpoints.down('sm')]: {
      fontSize: 28,
      lineHeight: '28px',
      maxWidth: '100%'
    }
  },
  showcaseDescription: {
    paddingLeft: 4,
    color: lightGray,
    fontWeight: 300,
    fontSize: 18
  },
  showcaseGroupText: {
    fontWeight: 400,
    fontSize: 24
  },
  showcaseScrollDown: {
    width: '100%',
    zIndex: 1000,
    position: 'absolute',
    textAlign: 'center',
    '&>img': {
      width: 75
    },
    [theme.breakpoints.down('sm')]: {
      display: 'inline-block'
    }
  },
  pdfDocument: {
    height: 'calc(100% - 60px)'
  },
  centerPdf: {
    margin: '0 auto',
    width: '100%',
    height: '100%',
    overflow: 'auto'
  },
  pdfControlsContainer: {
    marginBottom: 15
  },
  pdfPageNumbers: {
    margin: '0px 0px 10px',
    color: white,
    fontFamily: '"Gotham" !important',
    fontSize: 14
  },
  pageNumber: {
    fontFamily: '"Open Sans" !important'
  },
  pdfZoomContainer: {
    marginLeft: 10
  },
  pdfButton: {
    marginRight: 5,
    borderRadius: 12,
    padding: 5,
    lineHeight: 1.25
  },
  pdfZoom: {
    margin: '0px 5px'
  },
  pdfScale: {
    fontFamily: '"Gotham"',
    color: white,
    marginLeft: 5
  },
  pdfZoomIcon: {
    color: energy,
    width: 32,
    height: 32
  },
  pageLoading: {
    position: 'absolute',
    margin: 'auto',
    top: '30%',
    left: 1,
    bottom: 0,
    right: 0
  },
  contactAdministrator: {
    color: white,
    fontWeight: 300,
    outline: 'none !important',
    padding: '0px 3px'
  },
  trainingButton: {
    marginTop: 4,
    padding: '0px 10px',
    fontSize: '.8rem',
    lineHeight: 1.5
  },
  downloadAppsButtonContainer: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      flexBasis: '100%',
      borderBottom: `1px solid ${white}`,
      marginRight: 20,
      paddingBottom: '20px !important'
    }
  },
  downloadAppsButton: {
    position: 'relative',
    top: -1,
    margin: '0 20px 0 0',
    fontSize: '.8rem',
    height: 40,
    width: 140,
    border: `1px solid ${energy}`,
    color: white,
    background: 'none',
    '&:hover': {
      backgroundColor: energy,
      color: white
    },
    [theme.breakpoints.down('md')]: {
      width: 124,
      height: 35,
      padding: 7,
      marginRight: 10
    }
  },
  loginButtonHeader: {
    fontSize: '.9rem',
    width: 110,
    height: 35,
    float: 'right',
    borderColor: white,
    '&:hover': {
      backgroundColor: white,
      color: notBlack
    },
    [theme.breakpoints.down('xs')]: {
      width: 80,
      height: 30,
      fontSize: '.8rem',
    }
  },
  sessionDetailsListContainer: {
    height: 'calc(100% - 100px)',
    overflow: 'auto',
    marginTop: 30
  },
  sessionDetailsList: {
    marginTop: 0,
    fontFamily: '"Gotham", "Helvetica", "Arial", sans-serif',
    fontSize: 15,
    color: dolphin,
    '&>li': {
      marginTop: 5
    }
  },
  // energy, darkEnergy, navy, teal, torquoise, darkTorquoise, peacock, darkPeacock, lightDolphin, dolphin, darkDolphin
  sessionDetailsDate: {
    color: peacock
  },
  sessionDetailsUser: {
    color: notBlack
  },
  sessionDetailsItem: {
    color: teal
  },
  sessionDetailsContact: {
    color: torquoise
  },
  sessionDetailsDuration: {
    color: energy
  },
  reactPlayerWrapper: {
    position: 'relative',
    paddingTop: '56.25%' /* Player ratio: 100 / (1280 / 720) */
  },
  reactPlayer: {
    position: 'absolute',
    top: 0,
    left: 0,
  },
  tooltip: {
    position: 'relative',
    display: 'inline-block'
  },
  tooltiptext: {
    visibility: 'hidden',
    width: 120,
    backgroundColor: notBlack,
    color: white,
    textAlign: 'center',
    borderRadius: 6,
    padding: '5px 0px',
    
    /* Position the tooltip */
    position: 'absolute',
    zIndex: 1,
    bottom: '100%',
    left: '50%',
    marginLeft: -60
  },
  tooltip: {
    '&:hover': { 
      '& tooltiptext': {
        visibility: 'visible'
      }
    }
  },
  headerShareIcon: {
    marginLeft: 5
  },
  libraryShareIcon: {
    color: torquoise,
    height: '24px',
    width: '24px',
    position: 'relative',
    top: 5,
    [theme.breakpoints.down('sm')]: {
      height: '23px',
      width: '23px'
    }
  },
  selectedLibraryShareIcon: {
    color: energy
  },
  shareBadge: {
    top: -3
  },
  shareBadgeGoLive: {
    zIndex: 10,
    backgroundColor: white,
    color: verbPurple,
    top: 2,
    right: 2
  },
  shareBadgeDark: {
    backgroundColor: notBlack,
    color: white
  },
  returnToLibrary: {
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 15
    }
  },
  shareHeading: {
    marginTop: 31,
    fontSize: 25,
    paddingBottom: 5,
    [theme.breakpoints.down('sm')]: {
      marginTop: 20,
      fontSize: 20
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: 20,
      fontSize: 18
    }
  },
  shareHeading2: {
    marginTop: 20
  },
  shareHeadingLine: {
    margin: '40px 0 20px'
  },
  onboardingWelcomeImage: {
    width: '95%',
    maxWidth: 500
  },
  brandPaper: {
    maxWidth: 300
  },
  generateLivestreamContainer: {
    height: 30,
    marginLeft: 16
  },
  livestreamContainer: {
    marginLeft: 16,
    border: `1px solid ${lightGray}`,
    padding: 15,
    [theme.breakpoints.down('sm')]: {
      margin: '0px 10px'
    }
  },
  livestreamH5: {
    paddingBottom: 10,
    color: darkGray
  },
  copyLivestreamLink: {
    marginRight: 5,
    fontSize: 13,
    lineHeight: 1.45
  },
  generateLivestreamLink: {
    float: 'left',
    marginTop: 5
  },
  livestreamLoading: {
    float: 'left',
    height: 40,
    width: 40
  },
  livestreamLink: {
    fontWeight: 600,
    lineBreak: 'anywhere'
  },
  versionNumber: {
    textAlign: 'right',
    marginTop: 5,
    color: verbDarkPurple
  },
  createAccountLabel: {
    position: 'relative',
    top: 5,
    color: `${white} !important`,
    fontSize: '.85rem !important'
  },
  createAccountCheckbox: {
    color: `${white} !important`,
    paddingLeft: 0
  },
  createAccountLink: {
    fontWeight: 600
  },
  createAccountInputField: {
    padding: '0px 14px',
    height: '2.7em',
    fontSize: '.85rem'
  },
  createAccountInputLabel: {
    fontSize: '.85rem'
  },
  billedAnnually: {
    color: verbPurple,
    fontWeight: 600
  },
  billingFormGroup: {
    flexDirection: 'row'
  },
  downloadDesktopContainer: {
    textAlign: 'center',
    maxWidth: 500,
    borderRight: `1px solid rgba(255, 255, 255, 0.35)`, 
    [theme.breakpoints.down('sm')]: {
      border: 'none'
    }
  },
  hideOnMobile: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  showOnMobile: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'block'
    }
  },
  hideOnMedium: {
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  tableMenu: {
    [theme.breakpoints.down('sm')]: {
      zIndex: '13000 !important'
    }
  },
  bottomShareDrawer: {
    left: 'initial',
    height: 500,
    width: 'calc(100vw * .7)'
  },
  footerLiveOnly: {
    position: 'fixed',
    bottom: 0,
    height: 60,
    backgroundColor: black,
    zIndex: 100,
    width: '100%'
  },
  footerLiveOnlyButtons: {
    width: '100%',
    height: '100%',
    margin: 0,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },
  mainRightGrid: {
    height: '100%'
  },
  mainRightGridLiveOnly: {
    [theme.breakpoints.down('sm')]: {
      height: 'calc(100% - 70px)'
    }
  },
  mainRightGridShareCartLiveOnly: {
    [theme.breakpoints.down('sm')]: {
      height: 'calc(100% - 50px)'
    }
  },
  mainRightGridAppUser: {
    [theme.breakpoints.down('md')]: {
      height: 'calc(100% - 70px)'
    }
  },
  mainRightGridShareCartAppUser: {
    [theme.breakpoints.down('md')]: {
      height: 'calc(100% - 50px)'
    }
  },
  contactsFeedName: {
    cursor: 'pointer',
    fontWeight: 600,
    color: verbPurple
  },
  contactsItemRowClick: {
    cursor: 'pointer',
    color: verbSecondary
  },
  contactsCard: {
    marginBottom: 15,
    cursor: 'pointer'
  },
  contactsCardTimeline: {
    cursor: 'default'
  },
  contactsCardContent: {
    padding: '15px !important'
  },
  contactsItemsList: {
    margin: 0,
    listStyleType: 'none',
    paddingLeft: 20
  },
  contactsListArrow: {
    cursor: 'pointer',
    width: 25,
    height: 25,
    fontWeight: 600
  },
  contactsDropdown: {
    width: '100%',
    maxWidth: 250
  },
  contactsColumnHeaderContainer: {
    height: 40,
    marginBottom: 10
  },
  contactsColumnHeader: {
    color: verbSecondary,
    fontSize: 17,
    fontWeight: 600
  },
  contactsButton: {
    minWidth: 50,
    padding: '4px 16px 3px 10px',
    [theme.breakpoints.down(700)]: {
      padding: '4px 5px 3px',
      minWidth: 30,
      borderRadius: 7
    }
  },
  contactsCardName: {
    fontSize: 15,
    color: verbSecondary,
    fontWeight: 600
  },
  contactsFeedItem: {
    margin: '5px 0 0 13px',
    fontSize: 13
  },
  contactsFeedItemIcon: {
    marginRight: 5,
    width: 13,
    height: 13,
    position: 'relative',
    top: 2
  },
  contactsFeedItemTimeline: {
    fontSize: 14,
    '&:nth-of-type(n+2)': {
      marginTop: 10
    }
  },
  downloadFooter: {
    margin: '40px auto',
    background: 'rgba(0, 0, 0, 0.25)',
    width: '95%',
    maxWidth: 1100,
    borderRadius: 40,
    [theme.breakpoints.down('xs')]: {
      margin: '40px auto 100px'
    }
  },
  contactsItemsSub: {
    fontSize: 13,
    fontWeight: 400,
    color: darkGray,
    WebkitBoxDecorationBreak: 'clone',
    boxDecorationBreak: 'clone'
  },
  contactsItemsSubDuration: {
    color: verbPrimary,
    whiteSpace: 'nowrap'
  },
  contactsItemRow: {
    marginLeft: 8,
    WebkitBoxDecorationBreak: 'clone',
    boxDecorationBreak: 'clone',
    '&:nth-of-type(n+2)': {
      marginTop: 7
    }
  },
  contactsItemRowNoMargin: {
    marginTop: '4px !important'
  },
  contactsListDisc: {
    position: 'relative', 
    top: -1, 
    width: 6,
    height: 6,
    margin: '0 3px 0 0'
  },
  selectedAppProfile: {
    color: verbPrimary,
    fontWeight: 600
  },
  codeBlockArea: {
    color: torquoise,
    backgroundColor: lightGray,
    padding: 20
  },
  infoRefreshIcon: {
    position: 'relative',
    top: 3,
    marginRight: 3,
    color: verbPurple
  },
  lockedContentGroup: {
    '&>svg': {
      top: -3,
      fontSize: 18
    }
  },
  newGridHeight: {
    position: 'relative',
    height: '100%',
    paddingBottom: 10,
    [theme.breakpoints.down('xs')]: {
      paddingBottom: 28
    }
  },
  noRowPointer: {
    cursor: 'initial'
  },
  viewerContainer: {
    display: 'flex',
    height: 'calc(100% - 70px)'
  },
  interactiveVideoAnalyticsContainer: {
    height: 'calc(100% - 40px)',
    overflowY: 'scroll',
    overflowX: 'hidden'
  },
  bigNumberContainerInteractiveVideoAnalytics: {
    paddingTop: '0px !important'
  },
  tableContainerSmall: {
    overflowX: 'hidden',
    overflowY: 'auto'
  }
})